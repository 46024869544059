import { TypeDependencyProvider } from "@/services/typeDependencyProvider";

// Grab all the *Formatter.js files in this folder (exluding subfolders).
const context = require.context(".", false, /.*Formatter\.js$/);
const formatterProvider = new TypeDependencyProvider(context, false);

export function getFormatter(type) {
    const service = formatterProvider.getDependency(type);
    return service?.format ?? ((v) => v);
}

export function format(type, value) {
    const formatter = getFormatter(type);
    return formatter(value);
}

export default {
    getFormatter,
    format,
};
