import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import "./mixins/mixins";
import exporter from "./services/exporter";
import setupInterceptor from "./helpers/interceptor";
import labels from "chartjs-plugin-datalabels";
import LivemineHub from "./plugins/liveminehub";
import VueCytoscape from "vue-cytoscape";

import "./components";
import "typeface-roboto/index.css";

Vue.config.productionTip = false;
Vue.prototype.locale = window.navigator.language;
Vue.use(labels);
Vue.use(LivemineHub);
Vue.use(VueCytoscape);

setupInterceptor();

new Vue({
    router,
    store,
    vuetify,
    exporter,
    render: (h) => h(App),
}).$mount("#app");
