import Vue from "vue";
import errorUtility from "../services/errorUtility";

export default Vue.mixin({
    data() {
        return {
            isRunning: false,
        };
    },

    methods: {
        async command(action) {
            if (this.isRunning) {
                return;
            }

            try {
                this.isRunning = true;
                await Promise.resolve(action());
            } catch (e) {
                this.handleError(e);
            } finally {
                this.isRunning = false;
            }
        },

        runCommand(viewModel, loadingName, callback, throwExceptions) {
            viewModel[loadingName] = true;
            if (throwExceptions) {
                try {
                    return callback();
                } finally {
                    viewModel[loadingName] = false;
                }
            } else {
                try {
                    return callback();
                } catch (e) {
                    errorUtility.handleServerError(e);
                } finally {
                    viewModel[loadingName] = false;
                }
            }
        },

        async runAsyncCommand(viewModel, loadingName, callback, throwExceptions) {
            viewModel[loadingName] = true;
            if (throwExceptions) {
                try {
                    return await callback();
                } finally {
                    viewModel[loadingName] = false;
                }
            } else {
                try {
                    return await callback();
                } catch (e) {
                    errorUtility.handleServerError(e);
                } finally {
                    viewModel[loadingName] = false;
                }
            }
        },
    },
});
