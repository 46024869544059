export const help = {
    namespaced: true,
    state: {
        helpLinks: {
            webMainSupportPage:
                "https://liveminehelp.zendesk.com/hc/en-us/sections/360009305911-Webapp-Help-Files-and-Training", // used: views/Home.vue
            createZendeskAccount:
                "https://liveminehelp.zendesk.com/hc/en-us/restricted?return_to=https%3A%2F%2Fliveminehelp.zendesk.com%2Fhc%2Fen-us", // used: views/Home.vue
            shiftReview: "https://liveminehelp.zendesk.com/hc/en-us/articles/360054875271-Shift-Review", // Used: shiftReview/ShiftReview.vue
            operators:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360044648912-User-Management-Operators-Tablets", // Used: tableViews/Operators.vue
            users: "https://liveminehelp.zendesk.com/hc/en-us/articles/360045143191-User-Management-Administrators-Desktop#h_01FFH87J3BS2BMFJ05KFW182XR", // Used: views/Users.vue
            roles: "https://liveminehelp.zendesk.com/hc/en-us/articles/360045143191-User-Management-Administrators-Desktop#h_01FFH8CQ2V7BW31JE73E0CZ8JC", // Used: views/Users.vue
            activities: "https://liveminehelp.zendesk.com/hc/en-us/articles/360044698232-Mine-Management-Activities", // Used: tableViews/Activities.vue
            shifts: "https://liveminehelp.zendesk.com/hc/en-us/articles/360044700112-Mine-Management-Shifts", // Used: tableViews/Shifts.vue
            products: "https://liveminehelp.zendesk.com/hc/en-us/articles/360045145531-Mine-Management-Products", // Used: tableViews/Products.vue
            units: "https://liveminehelp.zendesk.com/hc/en-us/articles/360044701512-Mine-Management-Unit-of-Measure", // Used: tableViews/TimeUsageCategories.vue
            timeUsageCategories:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/4405507134221-Time-Management-Time-Usage-Categories", // Used: tableViews/TimeUsageCategories.vue
            timeUsageActivities:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/4405591363853-Time-Management-Time-Usage-Activities", // Used: tableViews/TimeUsageActivities.vue
            locationTypes:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360044746512-Location-Management-Location-Categories", // Used: tableViews/LocationTypes.vue
            locations: "https://liveminehelp.zendesk.com/hc/en-us/articles/360044747052-Location-Management-Locations", // Used: tableViews/Location.vue
            makes: "https://liveminehelp.zendesk.com/hc/en-us/articles/360045191211-Equipment-Management-Equipment-Makes", // Used: tableViews/Makes.vue
            models: "https://liveminehelp.zendesk.com/hc/en-us/articles/360044749512-Equipment-Management-Equipment-Models", // Used: tableViews/Models.vue
            equipments:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045195011-Equipment-Management-Equipment", // Used: tableViews/Equipments.vue
            equipmentStatuses:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045268851-Equipment-Management-Statuses", // Used: tableViews/EquipmentStatuses.vue
            prestart:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360044932612-Equipment-Management-Prestart-Checklists", // Used: tableViews/Checklists.vue
            meters: "https://liveminehelp.zendesk.com/hc/en-us/articles/360044993452-Equipment-Management-Meters", // Used: tableViews/Meters.vue
            inventory: "https://liveminehelp.zendesk.com/hc/en-us/articles/360045150312-Inventory-Management-Inventory", // Used: tableViews/Consumables.vue
            inventoryCategories:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045149012-Inventory-Management-Inventory-Categories", // Used: tableViews/ConsumableGroups.vue
            // Development Drilling
            developmentDrilling:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045638671-Module-Management-Development-Drilling",
            developmentDrillActivities:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045638671-Module-Management-Development-Drilling#h_01FFH52J4P4SEWVP76DZDYP2HC",
            developmentDrillCutLengths:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045638671-Module-Management-Development-Drilling#h_01FFH541AA50EQ9V9G5XPW38XY",
            developmentDrillTypes:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045638671-Module-Management-Development-Drilling#h_01FFH54NQEGYN8CMFQ08HG3KFH",
            developmentDrillStandards:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360045638671-Module-Management-Development-Drilling#h_01FFH54ZCG9YG7YJJ9KW2D3PA2",
            // Production Drilling
            productionDrilling:
                "https://liveminehelp.zendesk.com/hc/en-us/sections/360009305911-Webapp-Help-Files-and-Training",
            productionDrillActivities:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360052577492-Module-Management-Production-Drilling#h_01FFH7DPYC6CCJG9HQR0JARQEH",
            productionDrillPlans:
                "https://liveminehelp.zendesk.com/hc/en-us/articles/360052577492-Module-Management-Production-Drilling#h_01FFH7DYX4KZ73R5CZG3QRZ7YP",
        },
    },
    mutations: {
        helpLinks(state, links) {
            state.helpLinks = links;
        },
    },
};
