<template>
    <v-card>
        <v-card-title>
            <v-stepper v-model="stepperPageIndex">
                <v-stepper-header>
                    <template v-for="(stepperPage, i) in stepperPages">
                        <v-stepper-step :complete="stepperPage > i + 1" :step="i + 1" :key="stepperPage.title">
                            {{ stepperPage.title }}
                        </v-stepper-step>
                        <v-divider :key="i" />
                    </template>
                    <v-stepper-step :step="stepperPages.length + 1">{{ finalStepperPage.title }}</v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-expand-transition>
                <v-stepper v-model="stepperPageIndex">
                    <template v-for="(stepperPage, i) in stepperPages">
                        <v-stepper-content :step="i + 1" :key="i">
                            <component
                                :is="stepperPage.selector"
                                :filter="stepperPage.filter"
                                v-model="stepperPage.item"
                                @cancel="cancel"
                                @input="next(i)"
                                :module-id="moduleId"
                                :key="selectorKey"
                            />
                        </v-stepper-content>
                    </template>
                    <v-stepper-content :step="stepperPages.length + 1">
                        <component
                            :is="finalStepperPage.form"
                            :filter="finalStepperPage.filter"
                            v-model="finalStepperPage.item"
                            @cancel="cancel"
                            @save="onSave"
                        />
                    </v-stepper-content>
                </v-stepper>
            </v-expand-transition>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="error" @click="cancel">Cancel</v-btn>
            <v-btn v-if="canGoBack" color="primary" @click="back">Back</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Vue from "vue";

    export default Vue.component("wizard-base", {
        props: ["filter", "moduleId", "stepperPages", "finalStepperPage"],
        data() {
            return {
                stepperPageIndex: 1,
                selectorKey: 0,
            };
        },
        mounted() {
            this.stepperPages[0].filter = { ...this.stepperPages[0].filter, ...this.filter };
        },
        methods: {
            reset() {
                for (var i = 0; i < this.stepperPages.length; i++) {
                    this.stepperPages[i].item = {};
                }
                this.finalStepperPage.item = {};
                this.stepperPageIndex = 1;
            },
            back() {
                this.stepperPageIndex--;
            },
            next(index) {
                var foreignKeys =
                    index != this.stepperPages.length - 1
                        ? this.stepperPages[this.stepperPageIndex].foreignKeys
                        : this.finalStepperPage.foreignKeys;
                if (foreignKeys != null) {
                    for (var i = 0; i < foreignKeys.length; i++) {
                        if (index != this.stepperPages.length - 1) {
                            this.$set(
                                this.stepperPages[this.stepperPageIndex].filter,
                                foreignKeys[i].key,
                                this.stepperPages[foreignKeys[i].index].item[foreignKeys[i].field]
                            );
                        } else {
                            this.$set(
                                this.finalStepperPage.filter,
                                foreignKeys[i].key,
                                this.stepperPages[foreignKeys[i].index].item[foreignKeys[i].field]
                            );
                        }
                    }
                }
                if (index != this.stepperPages.length - 1) {
                    this.$set(this.stepperPages[this.stepperPageIndex], "item", {});
                    this.selectorKey++;
                } else {
                    this.$set(this.finalStepperPage, "item", {});
                }
                this.stepperPageIndex++;
            },
            onSave() {
                this.$emit("save", this.finalStepperPage.item);
            },
            cancel() {
                this.reset();
                this.$emit("cancel");
            },
        },
        computed: {
            canGoBack() {
                return this.stepperPageIndex > 1;
            },
            canAdd() {
                return this.stepperPageIndex < this.stepperPages.length + 1;
            },
            canSave() {
                return this.stepperPageIndex == this.stepperPages.length + 1;
            },
        },
    });
</script>
