import Vue from "vue";
import { camelToKebabCase } from "@/services/stringUtility";

// Grab all the *.vue files in this folder (exluding subfolders).
const requireComponent = require.context(".", false, /^.\/[\w-]+\.vue$/);

// Register these with Vue.component so we don't need to import them everwhere.
requireComponent.keys().forEach((filePath) => {
    // TODO: remove this check so that all components can be registered automatically.
    if (
        !filePath.endsWith("AppFieldIcon.vue") &&
        !filePath.endsWith("AppFieldMenu.vue") &&
        !filePath.endsWith("DateField.vue")
    ) {
        return;
    }

    const config = requireComponent(filePath);
    const name = camelToKebabCase(filePath.slice(2, -4));
    Vue.component(name, config.default || config);
});
