import axios from "axios";
import store from "../store/store";
import dateUtility from "../services/dateUtility";
import JSZip from "jszip";

function saveBlob(response) {
    var blob = new Blob([response.data], { type: response.headers["content-type"] });
    var downloadUrl = window.URL.createObjectURL(blob);
    var filename = getFilename(response);
    var link = document.createElement("a");

    if (typeof link.download === "undefined") {
        window.location.href = downloadUrl;
    } else {
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
    }
}

function getFilename(response, fileType = ".xslx") {
    var disposition = response.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, "");
        }

        return "export" + fileType;
    }
}

function downloadAsExcel(data) {
    if (data == null || data.items == null || !data.items.length) {
        store.dispatch("alert/error", "There is no data to export.");
        return;
    }

    function isObject(value) {
        return typeof value === "object" && value !== null;
    }

    function hasProperties(value) {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                return true;
            }
        }
        return false;
    }

    function isIdLabel(value) {
        return "id" in value && "label" in value;
    }

    var items = [];
    data.items.forEach((e) => {
        var item = Object.assign({}, e);

        for (var property in item) {
            var value = item[property];

            if (!isObject(value)) {
                continue;
            }

            if (isIdLabel(value)) {
                item[property] = value.label;
            } else if (!hasProperties(value)) {
                item[property] = "";
            }
        }

        items.push(item);
    });

    var parameters = {
        title: data.title,
        items: items,
        clientNow: dateUtility.now().toISO(),
    };

    return axios
        .post("/api/Export/ToExcel", parameters, { responseType: "blob" })
        .then(this.saveBlob)
        .catch((error) => {
            store.dispatch("alert/error", "There was an error downloading the file. " + error);
        });
}

function downloadAsJson(response) {
    if (response == null || response.data == null) {
        store.dispatch("alert/error", "There is no data to export.");
        return;
    }
    var json = JSON.stringify(response.data, null, 4);
    var blob = new Blob([json], { type: response.headers["content-type"] });
    var fileName = getFilename(response, ".json");

    var zipName = dateUtility.now().toISO() + " " + fileName;

    var zip = new JSZip();
    zip.file(fileName + ".json", blob);
    zip.generateAsync({ type: "blob" }).then((content) => {
        var downloadUrl = window.URL.createObjectURL(content);
        var link = document.createElement("a");
        link.href = downloadUrl;
        link.download = zipName + ".zip";
        document.body.appendChild(link);
        link.click();
    });
}

export default {
    saveBlob,
    downloadAsExcel,
    downloadAsJson,
};
