<template>
    <v-menu v-model="show" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <app-field-icon v-on="on">
                {{ icon }}
            </app-field-icon>
        </template>
        <slot />
    </v-menu>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: "mdi-circle",
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                show: false,
            };
        },
        watch: {
            show(show) {
                if (show !== this.value) {
                    this.$emit("input", show);
                }
            },
            value: {
                immediate: true,
                handler(value) {
                    if (this.show !== value) {
                        this.show = value;
                    }
                },
            },
        },
    };
</script>
