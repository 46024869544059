<template>
    <v-dialog v-model="dialog" :max-width="maxWidth">
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :open="open">
                <v-btn dark v-bind="attrs" v-on="on">Click Me</v-btn>
            </slot>
        </template>

        <v-card>
            <v-card-title>Confirm</v-card-title>

            <v-card-text>
                <v-alert v-if="irreversible" outlined dense type="error" prominent>
                    This action cannot be undone.
                </v-alert>

                <slot name="hint"></slot>

                <span class="subtitle-1">{{ message }}</span>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Cancel</v-btn>
                <v-btn color="primary" text @click="confirm">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            irreversible: {
                type: Boolean,
                default: () => false,
            },
            message: {
                type: String,
                default: () => "Are you sure you want to remove this item?",
            },
            maxWidth: {
                type: String,
                default: () => "500px",
            },
        },
        data() {
            return {
                dialog: false,
            };
        },
        methods: {
            open() {
                this.dialog = true;
            },
            confirm() {
                this.dialog = false;
                this.$emit("confirm");
            },
        },
    };
</script>
