import axios from "axios";

export const settings = {
    namespaced: true,
    state: {
        isLoaded: false,
        developmentDrillApprovalMode: 0,
        pendingUpdate: true,
        faceSamplingIntegrationMode: null,
        maxFileSizeMB: 4,
        enableWeightDistance: false,
        culture: "en-AU",
        timeZone: "Australia/Perth",
    },
    getters: {
        pendingUpdate: (state) => state.pendingUpdate,
        settings(state) {
            return state;
        },
    },
    actions: {
        async initialize({ commit }) {
            try {
                var response = await axios.get("/api/Settings/GetWeb");
                const settings = response.data;

                commit("culture", settings.culture);
                commit("timeZone", settings.timeZone);
                commit("developmentDrillApprovalMode", settings.developmentDrillApprovalMode);
                commit("faceSamplingIntegrationMode", settings.faceSamplingIntegrationMode);
                commit("maxFileSizeMB", settings.maxFileSizeMB);
                commit("enableWeightDistance", settings.enableWeightDistance);

                commit("isLoaded", true);
            } catch {
                /* let the interceptor handle any redirection to the login page. */
            }
        },
    },
    mutations: {
        setPendingUpdate(state, value) {
            state.pendingUpdate = value;
        },
        culture(state, culture) {
            state.culture = culture;
        },
        timeZone(state, timeZone) {
            state.timeZone = timeZone;
        },
        developmentDrillApprovalMode(state, developmentDrillApprovalMode) {
            state.developmentDrillApprovalMode = developmentDrillApprovalMode;
        },
        faceSamplingIntegrationMode(state, faceSamplingIntegrationMode) {
            state.faceSamplingIntegrationMode = faceSamplingIntegrationMode;
        },
        maxFileSizeMB(state, maxFileSizeMB) {
            state.maxFileSizeMB = maxFileSizeMB;
        },
        enableWeightDistance(state, enableWeightDistance) {
            state.enableWeightDistance = enableWeightDistance;
        },
        isLoaded(state, isLoaded) {
            state.isLoaded = isLoaded;
        },
    },
};
