<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">Import Data from Excel File</span>
                <v-spacer />
                <a class="text-subtitle-1" :href="template">Download Template</a>
            </v-card-title>

            <v-card-text>
                <v-col cols="4" style="padding-left: 0px">
                    <v-file-input
                        v-model="excelFile"
                        accept=".xls,.xlsx"
                        label="Upload an Excel file"
                        :truncate-length="50"
                        @change="fileChange($event)"
                        clearable
                        outlined
                        dense
                        hide-details
                    ></v-file-input>
                </v-col>
            </v-card-text>

            <v-card-text>
                <v-form ref="editForm" v-model="valid" @submit.prevent>
                    <v-row>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :footer-props="{ 'items-per-page-options': [-1] }"
                            item-key="index"
                            disable-pagination
                            hide-default-footer
                            height="60vh"
                            fixed-header
                            style="width: 100%"
                        >
                            <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                                <v-tooltip v-if="!isNullOrWhiteSpace(header.toolTip)" bottom :key="header.value">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ header.text }}</span>
                                    </template>
                                    <span>{{ header.toolTip }}</span>
                                </v-tooltip>
                                <span v-else :key="`else-${header.value}`">{{ header.text }}</span>
                            </template>

                            <template v-for="field in editableFields" v-slot:[slotname(field)]="{ item }">
                                <v-text-field
                                    :key="field.name"
                                    class="mt-4"
                                    v-model="item[field.name]"
                                    :rules="computedRules[field.name]"
                                    :counter="field.max"
                                    :type="field.type"
                                    dense
                                    outlined
                                ></v-text-field>
                            </template>

                            <template v-slot:item.action="{ item }">
                                <v-icon @click="deleteItem(item.index)">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn color="quaternary" text @click="cancel">Cancel</v-btn>
                <v-btn color="quaternary" text @click="save(true)">Save</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="errorDialog" persistent max-width="50vw">
            <v-card>
                <v-card-title>
                    <v-alert type="warning">
                        {{ errorMessage }}
                    </v-alert>
                </v-card-title>
                <v-divider />
                <v-card-text style="overflow-y: scroll; max-height: 500px">
                    <v-list>
                        <v-list-item v-for="(error, index) in errors" :key="index">
                            <v-list-item-title>{{ error }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="quaternary" text @click="errorDialog = false">Cancel</v-btn>
                    <v-btn color="quaternary" text @click="save(false)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import axios from "axios";
    import errorUtility from "../services/errorUtility";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";

    export default Vue.component("excel-importer", {
        props: ["fields", "type", "template", "errorMessage", "checkErrors"],

        data() {
            return {
                valid: true,
                isSaving: false,
                excelFile: null,

                items: [],
                errors: [],
                errorDialog: false,
            };
        },

        computed: {
            headers() {
                const mappedFields = this.fields.map((x) => {
                    return { text: x.label, value: x.name, toolTip: x.toolTip };
                });
                mappedFields.push({ text: "", value: "action", width: "50px" });
                return mappedFields;
            },

            editableFields() {
                return this.fields.filter((field) => field.editable);
            },

            computedRules() {
                let rules = {};
                if (this.fields) {
                    this.fields.forEach((f) => {
                        rules[f.name] = this.getRules(f);
                    });
                }
                return rules;
            },
        },

        methods: {
            slotname(field) {
                return `item.${field.name}`;
            },

            getRules(field) {
                let rules = [];
                if (field?.required) {
                    rules.push((v) => !!v || "Required");
                }

                if (field?.max != null && !isNaN(field?.max)) {
                    if (field.type === "number") {
                        rules.push((v) => !v || v <= field.max || `Cannot be greater than ${field.max}`);
                    } else if (field.type === "text") {
                        rules.push((v) => !v || v.length <= field.max || `Max ${field.max} characters`);
                    }
                }

                return rules;
            },

            async fileChange(file) {
                if (file != null) {
                    let fileList = new FormData();
                    fileList.append("file", file, file.name);

                    try {
                        var body = { headers: { "Content-Type": "multipart/form-data" } };
                        const { data } = await axios.post("api/import/" + this.type + "/Read", fileList, body);
                        this.items = data
                            .map((items, index) => ({
                                ...items,
                                index: index,
                            }))
                            .sort(this.compare);
                    } catch (error) {
                        errorUtility.handleServerError(error);
                    } finally {
                        await Vue.nextTick();
                        this.$refs.editForm.validate();
                    }
                }
            },

            /**
             * Sort items that have validation issues to the top of the list.
             */
            compare(a, b) {
                for (const field of this.editableFields) {
                    if (field.required) {
                        if (a[field.name] == null) return -1;
                        if (b[field.name] == null) return 1;
                    }

                    if (field?.max != null && !isNaN(field?.max)) {
                        if (
                            (field.type === "number" && a[field.name] > field.max) ||
                            (field.type === "text" && a[field.name].length > field.max)
                        ) {
                            return -1;
                        }

                        if (
                            (field.type === "number" && b[field.name] > field.max) ||
                            (field.type === "text" && b[field.name].length > field.max)
                        ) {
                            return 1;
                        }
                    }
                }

                if (a.index < b.index) {
                    return -1;
                } else if (a.index > b.index) {
                    return 1;
                } else {
                    return 0;
                }
            },

            deleteItem(index) {
                if (!confirm("Are you sure you want to delete this item from the import?")) {
                    return;
                }

                var actualIndex = this.items.findIndex((i) => i.index === index);
                if (actualIndex > -1) {
                    this.items.splice(actualIndex, 1);
                }
            },

            reset() {
                this.errorDialog = false;
                this.excelFile = null;
                this.items = [];

                var editForm = this.$refs.editForm;
                if (typeof editForm !== "undefined") {
                    editForm.resetValidation();
                }
            },

            cancel() {
                this.reset();
                this.$emit("cancel");
            },

            async save(checkErrors = false) {
                if (this.isSaving) {
                    return;
                }
                if (!this.$refs.editForm.validate()) {
                    this.items.sort(this.compare);
                    return;
                }

                try {
                    if (this.checkErrors && checkErrors) {
                        const data = { items: this.items, checkErrors: true };
                        const response = await axios.post("api/import/" + this.type, data);
                        if (response.data && response.data.length > 0) {
                            this.errors = response.data;
                            this.errorDialog = true;
                            return;
                        }
                    }
                    const data = { items: this.items, checkErrors: false };
                    await axios.post("api/import/" + this.type, data);
                } catch (error) {
                    errorUtility.handleServerError(error);
                } finally {
                    this.isSaving = false;
                }

                this.reset();
                this.$emit("save");
                this.$store.dispatch("alert/success", "New data has been added!");
                setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                }, 2000);
            },

            isNullOrWhiteSpace,
        },
    });
</script>
