// Enums are dictionary of names versus numbers. This is so they work in the same way as C# enums (and other languages) which allow multiple names to have the same number.

let userInterfaceEnum = {
    Load: 0,
    Haul: 1,
    DevelopmentDrill: 2,
    ProductionDrill: 3,
    ServiceCrew: 4,
    HaulOpenPit: 5,
    AirLeg: 6,
    DiamondDrill: 7,
    RaiseBore: 8,
    Charge: 9,
    Shotcrete: 10,
    CableBolt: 11,
    AgiTruck: 12,
    OpenPitDrill: 13,
};

export default userInterfaceEnum;
