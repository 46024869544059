<template>
    <div>
        <v-btn-toggle style="width: 100%" v-model="mode" mandatory>
            <v-btn class="ma-0" style="width: 50%" :disabled="items && items.length === 0">
                Select an existing activity
            </v-btn>
            <v-btn class="ma-0" style="width: 50%">Add new activity</v-btn>
        </v-btn-toggle>

        <v-list v-if="mode === 0" one-line dense>
            <v-list-item class="px-8" v-for="e in items" :key="e.id" @click="select(e)">
                <v-list-item-content>
                    <v-list-item-title>{{ e.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <activity-form
            v-if="mode === 1"
            :filter="filter"
            :module="module"
            v-model="editedItem"
            @save="onSave"
            @cancel="onCancel"
        />
    </div>
</template>

<script>
    import Vue from "vue";
    import axios from "axios";
    import errorUtility from "../services/errorUtility";

    export default Vue.component("activity-selector", {
        props: ["filter", "value", "module"],
        data() {
            return {
                items: [],
                moduleId: "",
                mode: 0,
                editedItem: null,
            };
        },
        methods: {
            async load() {
                this.editedItem = {};

                if (this.filterIsValid()) {
                    try {
                        await this.loadItems();
                    } catch (error) {
                        errorUtility.handleServerError(error);
                    }
                }

                this.mode = this.items.length > 0 ? 0 : 1;
            },
            async loadItems() {
                var params = {};
                if (typeof this.filter !== "undefined" && this.filter !== null) {
                    Object.keys(this.filter).forEach((key) => (params["filter." + key] = this.filter[key]));
                }
                let response = await axios.get("/api/Activities", { params });

                this.items = response.data.items;
            },
            onCancel() {
                this.$emit("cancel");
            },
            filterIsValid() {
                return this.filter;
            },
            select(item) {
                this.$emit("input", item);
            },
            async onSave(item) {
                this.mode = 0;

                this.items.push(item);
                this.$emit("input", item);
            },
        },
        watch: {
            filter: {
                immediate: true,
                handler() {
                    this.load();
                },
            },
        },
    });
</script>
