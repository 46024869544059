import operatorShiftTaskStatusEnum from "../enums/operatorShiftTaskStatusEnum.js";

let statusColors = {
    [operatorShiftTaskStatusEnum.Draft]: "#f7901e",
    [operatorShiftTaskStatusEnum.Assigned]: "#03A9F4",
    [operatorShiftTaskStatusEnum.Started]: "#545162",
    [operatorShiftTaskStatusEnum.Completed]: "#4CAF50",
    [operatorShiftTaskStatusEnum.CompletedWithChanges]: "#8BC34A",
    [operatorShiftTaskStatusEnum.NotCompleted]: "#F44336",
};

export { statusColors };
