<template>
    <v-select v-bind="$attrs" v-on="$listeners">
        <template v-slot:item="{ item, parent }" v-if="itemColor">
            <span :style="getItemStyle(item)">
                {{ getItemText(item, parent.itemText) }}
            </span>
        </template>
        <template v-slot:selection="{ item, parent }" v-if="itemColor">
            <span :style="getItemStyle(item)">
                {{ getItemText(item, parent.itemText) }}
            </span>
        </template>
    </v-select>
</template>
<script>
    import Vue from "vue";

    // Simple v-select wrapper component with individually colored items
    // It does not support slots (see FileInput component if slot passthrough is required)
    export default Vue.component("custom-select", {
        props: {
            // Property name of items’s color value.
            itemColor: String,
        },
        methods: {
            getItemStyle(item) {
                return this.itemColor && item && item[this.itemColor] ? { color: item[this.itemColor] } : null;
            },
            getItemText(item, itemText) {
                return item && itemText ? item[itemText] : item;
            },
        },
    });
</script>
