<template>
    <img :src="source" class="contain" />
</template>

<script>
    import Vue from "vue";

    export default Vue.component("file-image", {
        props: ["value"],

        computed: {
            source() {
                return `/api/file/${this.value}`;
            },
        },
    });
</script>
