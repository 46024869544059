<template>
    <v-dialog persistent v-model="showDialog" max-width="800px">
        <v-form ref="editForm" v-model="isValid" @submit="command(save)" @submit.prevent>
            <v-card>
                <v-card-title>
                    <span class="headline">Edit {{ attributes.label }}</span>
                </v-card-title>

                <v-card-text>
                    <component :is="component" v-model="internalValue" v-bind="attributes" autofocus />
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="quaternary" text @click="close">Cancel</v-btn>
                    <v-btn type="submit" color="quaternary" text>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import Vue from "vue";

    export default Vue.component("edit-field-dialog", {
        props: ["component", "attributes", "post", "value"],

        data() {
            return {
                showDialog: true,
                internalValue: null,
                isSaving: false,
                isValid: true,
            };
        },

        methods: {
            async save() {
                if (!this.$refs.editForm.validate()) {
                    return;
                }
                await this.post(this.internalValue);
                this.$emit("input", this.internalValue);
                this.close();
            },

            close() {
                this.showDialog = false;
            },
        },

        watch: {
            value: {
                immediate: true,
                deep: true,
                handler(value) {
                    this.internalValue = value;
                },
            },
        },
    });
</script>
