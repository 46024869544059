import { DateTime, Duration } from "luxon";
import { format } from "@/localization/formatting";
import { getLocale, getTimeZone } from "@/localization/localizationProvider";
import { isNullOrWhiteSpace } from "./stringUtility";

function isInt(value) {
    if (isNaN(value)) {
        return false;
    }
    var x = parseFloat(value);
    return (x | 0) === x;
}

export function formatDateOnly(value) {
    return format("dateonly", value);
}

export function formatDate(value) {
    return format("datetimeoffset", value);
}

export function formatUtcToOffset(value) {
    return format("datetime", value);
}

export function formatTime(value) {
    return format("time", value);
}

export function formatTimeWithOffset(value) {
    if (value == null) {
        return "";
    }

    return DateTime.fromISO(value, { setZone: true })
        .setLocale(getLocale())
        .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
}

export function convertTimeToDecimal(value) {
    if (typeof value === "undefined" || value === null) {
        return "";
    }

    var hour = parseFloat(value.substring(0, 1) != "0" ? value.substring(0, 2) : value.substring(1, 2));
    var minute = parseFloat(value.substring(3, 4) != "0" ? value.substring(3, 5) : value.substring(4, 5));
    minute = minute < 30 ? 0 : 30;
    return hour + minute / 60;
}

export function setTime(value, time) {
    var dateTime = DateTime.fromISO(value, { setZone: true });
    var date = dateTime.toISODate();
    var offset = toIsoOffset(dateTime.offset);
    return `${date}T${time}${offset}`;
}

export function addDays(value, days) {
    var dateTime = DateTime.fromISO(value, { setZone: true }).plus({ days: days });
    return dateTime.toISO();
}

export function formatTimespan(value, format = false) {
    if (typeof value === "undefined" || value === null) {
        return "";
    }

    var date = DateTime.fromISO(value);

    if (format) {
        return date.setLocale(getLocale()).toLocaleString(DateTime.TIME_SIMPLE);
    }

    var milliseconds = date.millisecond;
    var secondsToAdd = Math.round(milliseconds / 1000);
    date = date.plus({ seconds: secondsToAdd });
    return date.set({ milliseconds: 0 }).toISOTime({ suppressMilliseconds: true, includeOffset: false });
}

export function offsetMatchesCurrentTimeZone(dateTime) {
    let localDateTime = dateTime.setZone(getTimeZone());
    return dateTime.offset === localDateTime.offset;
}

export function formatOffset(offset) {
    if (offset === 0) return "UTC";

    let hours = parseInt(Math.abs(offset / 60));
    let minutes = Math.abs(offset % 60);
    let prefix = offset > 0 ? "+" : "-";

    if (minutes === 0) {
        minutes = "";
    } else if (minutes < 10) {
        minutes = `:0${minutes}`;
    } else {
        minutes = `:${minutes}`;
    }

    return `${prefix}${hours}${minutes}`;
}

export function toIsoOffset(offset) {
    if (offset === 0) return "Z";

    var hours = parseInt(Math.abs(offset / 60));
    var minutes = Math.abs(offset % 60);
    var prefix = offset > 0 ? "+" : "-";
    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;

    return `${prefix + hours}:${minutes}`;
}

export function tryParseOffset(offset) {
    // We expect the time offset to be something like this:
    // +8, -8, 8, 8:00, 08:00, +8:00, +08:00, -08:00, Z
    if (offset == null) {
        return { isValid: false };
    }

    if (offset == "Z") {
        // Z = Zulu = UTC+0:00
        return { isValid: true, offset: 0 };
    }

    // value in minutes.
    var value = null;

    if (isInt(offset)) {
        value = parseInt(Number(offset)) * 60;
    } else {
        var matches = offset.match(/^([+-]?)(\d?\d):(\d\d):?(\d\d)?$/);
        if (matches == null) {
            return { isValid: false };
        } else {
            var minutes = Number(matches[3]);
            if (minutes >= 60) {
                return { isValid: false };
            }

            var sign = matches[1] === "-" ? -1 : 1;
            value = sign * (Number(matches[2]) * 60 + minutes);
        }
    }

    if (value < -12 * 60 || value > 14 * 60) {
        return { isValid: false };
    }

    return { isValid: true, offset: value };
}

export function today() {
    return DateTime.fromISO(getCurrentDateIso(), { setZone: true });
}

export function getCurrentDateIso(includeOffset = true) {
    return toDateIso(now(), includeOffset);
}

export function nowIso() {
    return now().toISO();
}

function zeroPad(num, places) {
    // From https://stackoverflow.com/a/2998874
    return String(num).padStart(places, "0");
}

export function toDateIso(date, includeOffset = true) {
    let datePart = `${date.year}-${zeroPad(date.month, 2)}-${zeroPad(date.day, 2)}`;

    if (includeOffset) {
        let offsetPart = toIsoOffset(date.offset);
        return `${datePart}T00:00:00${offsetPart}`;
    }

    return `${datePart}T00:00:00`;
}

export function getDifference(value1, value2) {
    var dateTime1 = DateTime.fromISO(value1, { setZone: true });
    var dateTime2 = DateTime.fromISO(value2, { setZone: true });
    return dateTime1.diff(dateTime2);
}

export function parseDuration(text) {
    const regex = /^(\d?\d):(\d\d):(\d\d)$/;
    const result = text.match(regex);
    if (result) {
        const [hours, minutes, seconds] = result
            .slice(1)
            .map((s) => (typeof s === "undefined" ? 0 : s))
            .map((s) => parseInt(s));
        return Duration.fromObject({
            hours,
            minutes,
            seconds,
        });
    } else {
        return Duration.invalid("unparsable");
    }
}

export function emptyDuration() {
    return Duration.fromObject({});
}

export function updateDuration(duration, partName, value) {
    return Duration.fromObject(
        Object.assign({}, { hours: duration.hours, minutes: duration.minutes }, { [partName]: value })
    );
}

export function now() {
    return DateTime.local(getLocalizationOptions());
}

export function dateTimesEqual(dateTime1, dateTime2) {
    if (dateTime1 === null && dateTime2 === null) {
        return true;
    }

    if (dateTime1 === null || dateTime2 === null) {
        return false;
    }

    // This method is more reliable than equals() because we lose time zone information when
    // serializing then deserializing. This causing equals() to return false when we want it to
    // return true.
    return dateTime1.toISO() === dateTime2.toISO();
}

export function getDateFormatString(useBrowserLocale = false) {
    let dateTime = parseIso("9876-02-01T00:00:00.000Z", useBrowserLocale);

    return dateTime
        .toLocaleString(DateTime.DATE_SHORT)
        .replace(/01/g, "dd")
        .replace(/1/g, "d")
        .replace(/02/g, "MM")
        .replace(/2/g, "M")
        .replace(/9876/g, "yyyy")
        .replace(/76/g, "yy");
}

export function parseIso(isoDate, useBrowserLocale = false) {
    if (isNullOrWhiteSpace(isoDate)) {
        return null;
    }

    // zone options is set to the app time zone
    // This will be ignored if the input value has time zone.
    // If no time off set is specified in the input (e.g. date only string), it assumes it is in app time zone.
    // This is the same behaviour as localization/dateFormatter.js
    var options = { setZone: true, zone: getTimeZone() };

    // Using navigator.language is unreliable because browsers don't always use this to determine
    // how dates are formatted by default. Firefox for example, appears to use the OS setting. This
    // is hidden from the user because it can be used as a fingerprinting vector. Instead, the best
    // way to determine the locale format is to not specify a locale and let it arrive at whichever
    // default.
    if (!useBrowserLocale) {
        options.locale = getLocale();
    }

    return DateTime.fromISO(isoDate, options);
}

export function localize(dateTime) {
    if (dateTime == null) {
        return null;
    }
    return dateTime.setZone(getTimeZone()).setLocale(getLocale());
}

export function parseDate(text) {
    if (isNullOrWhiteSpace(text)) {
        return null;
    }
    return DateTime.fromFormat(text, "D", getLocalizationOptions());
}

export function parseFromFormat(text, format) {
    if (isNullOrWhiteSpace(text)) {
        return null;
    }
    let options = { setZone: true, zone: getTimeZone() };
    return DateTime.fromFormat(text, format, options);
}

function getLocalizationOptions() {
    return {
        zone: getTimeZone(),
        locale: getLocale(),
    };
}

export default {
    formatUtcToOffset,
    formatDate,
    formatDateOnly,
    formatTime,
    formatTimeWithOffset,
    formatTimespan,
    toIsoOffset,
    tryParseOffset,
    getCurrentDateIso,
    nowIso,
    toDateIso,
    setTime,
    convertTimeToDecimal,
    getDifference,
    addDays,
    today,
    parseDuration,
    emptyDuration,
    updateDuration,
    now,
};
