<template>
    <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <confirmation-dialog :message="confirmMessage" @confirm="confirmed">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="confirmDialog = true">
                            {{ isDefaultActivity ? "mdi-radiobox-marked" : "mdi-radiobox-blank" }}
                        </v-icon>
                    </template>
                </confirmation-dialog>
            </div>
        </template>
        <span>{{ toolTipMessage }}</span>
    </v-tooltip>
</template>

<script>
    import Vue from "vue";

    import ConfirmationDialog from "../components/ConfirmationDialog.vue";
    export default Vue.component("default-activity-button", {
        props: ["isDefaultActivity"],
        components: {
            ConfirmationDialog,
        },
        methods: {
            async confirmed() {
                if (this.isDefaultActivity) {
                    this.$emit("unset");
                } else {
                    this.$emit("set");
                }
            },
        },
        computed: {
            toolTipMessage() {
                return this.isDefaultActivity ? "Unset default activity" : "Set default activity";
            },
            confirmMessage() {
                return this.isDefaultActivity
                    ? "Are you sure you want to unset the default activity?"
                    : "Are you sure you want to set the activity as the default option?";
            },
        },
    });
</script>
