// Enums are dictionary of names versus numbers. This is so they work in the same way as C# enums (and other languages) which allow multiple names to have the same number.

let operatorShiftTaskStatusEnum = {
    Draft: 0,
    Assigned: 1,
    Started: 2,
    Completed: 3,
    CompletedWithChanges: 4,
    NotCompleted: 5,
};

export default operatorShiftTaskStatusEnum;
