export { toCamelCase, toPascalCase, camelToKebabCase, camelToTitleCase, camelToPascalCase, isNullOrWhiteSpace };

//https://stackoverflow.com/a/52551910
function toCamelCase(text) {
    return text
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, t) => t.toUpperCase());
}

//https://stackoverflow.com/a/53952925
function toPascalCase(string) {
    return `${string}`
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(new RegExp(/\s+(.)(\w*)/, "g"), ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
        .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

function camelToKebabCase(value) {
    if (value === null || value === "") {
        return value;
    }

    return value.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        (substring, offset) => (offset ? "-" : "") + substring.toLowerCase()
    );
}

function camelToTitleCase(value) {
    if (value === null || value === "") {
        return value;
    }

    return value
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        .replace(/^./, (s) => s.toUpperCase());
}

function camelToPascalCase(value) {
    if (value === null || value === "") {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

function isNullOrWhiteSpace(value) {
    return value == null || (typeof value === "string" && value.trim() == "");
}
