<template>
    <v-app id="livemine">
        <v-alert
            style="position: fixed"
            prominent
            :value="hasMessage"
            :type="getType"
            class="alert-bar"
            transition="scale-transition"
        >
            <v-row align="center">
                <v-col class="grow">{{ alert.message }}</v-col>
                <v-col class="shrink">
                    <v-icon v-if="alert.message" @click="closeAlert()">mdi-window-close</v-icon>
                </v-col>
            </v-row>
        </v-alert>
        <v-navigation-drawer
            v-if="loggedIn && !forceNewPassword"
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            app
        >
            <v-layout justify-space-between column fill-height>
                <Menu />

                <div justify-end style="padding: 10px 20px">
                    <img :src="storeLogo" class="nav-drawer-logo-image" />
                    <p class="branding-name">
                        {{ storeCompanyName }}
                    </p>
                </div>
            </v-layout>
        </v-navigation-drawer>
        <v-app-bar v-if="loggedIn" :clipped-left="$vuetify.breakpoint.lgAndUp" app color="secondary" dark>
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            </v-toolbar-title>
            <v-spacer />
            <img :src="require('./assets/livemine-logo-white.svg')" alt="LiveMine" class="app-bar-logo" />
            <v-spacer />
            <div style="padding-right: 20px">Version {{ versionNumber }}</div>
            <div style="padding-right: 5px">
                {{ username }}
            </div>
            <v-menu open-on-hover>
                <template v-slot:activator="{ on }">
                    <v-btn icon large v-on="on">
                        <v-avatar tile><v-icon>mdi-account</v-icon></v-avatar>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-title>Log out</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changePassword">
                        <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <template v-if="!forceNewPassword">
            <v-main v-if="showContent" :class="loggedIn ? null : 'pa-0'">
                <router-view :key="$route.fullPath" />
            </v-main>
        </template>
        <template v-else>
            <v-main v-if="showContent" :class="loggedIn ? null : 'pa-0'">
                <change-password />
            </v-main>
        </template>
    </v-app>
</template>

<script>
    import Menu from "./components/Menu";
    import ChangePassword from "./views/ChangePassword";
    import axios from "axios";
    import { mapState } from "vuex";
    import { mapFields } from "vuex-map-fields";
    import DefaultLogo from "./assets/livemine-logo.svg";

    export default {
        components: {
            Menu,
            ChangePassword,
        },
        data: () => ({
            versionNumber: null,
            username: null,
        }),
        beforeMount() {
            window.addEventListener(
                "touchstart",
                () => {
                    this.$store.commit("setIsTouchDevice", true);
                    window.removeEventListener("touchstart", this);
                },
                false
            );
        },
        mounted() {
            this.$store.dispatch("authentication/initialize");
            this.$store.dispatch("settings/initialize");

            this.getVersion();
            this.getUser();
        },
        created() {
            // Listen to events of interest coming from SignalR hub
            this.$livemineHub.$on("user-account-deactivated", this.onUserAccountDeactived);
            this.$livemineHub.$on("refresh-claims", this.onRefreshClaims);
            this.$livemineHub.$on("broadcast-message", this.onBroadcastMessage);
        },
        methods: {
            async getVersion() {
                var response = await axios.get("/api/version");
                this.versionNumber = response.data;
            },

            getUser() {
                var user = this.$store.state.authentication.user;
                if (user) {
                    this.username = user.username;
                }
            },

            logout() {
                this.$store.dispatch("authentication/logout");
            },

            changePassword() {
                this.$router.push({ path: "/change-password" });
            },

            closeAlert() {
                this.$store.dispatch("alert/clear");
            },
            onBroadcastMessage(message) {
                this.$store.dispatch("alert/info", message);
            },
            onUserAccountDeactived(userId) {
                // if this is me, log me out
                if (userId.userId == this.getCurrentUserId()) {
                    this.$store.dispatch("authentication/logout").then(() => {
                        this.$store.dispatch(
                            "alert/info",
                            "Your account has now been deactivated and you have been logged out. Please contact your Administrator."
                        );
                    });
                }
            },
            getCurrentUserId() {
                return this.$store.state.authentication.user.id;
            },
            onRefreshClaims() {
                this.$store.dispatch("authentication/logout").then(() => {
                    this.$store.dispatch("alert/info", "Your account permissions have been updated.");
                });
            },
        },
        computed: {
            ...mapFields("navigation", ["drawer"]),
            ...mapState({
                companyName: (s) => s.branding.companyName,
                status: (s) => s.authentication.status,
                user: (s) => s.authentication.user,
                alert: (s) => s.alert,
                loggedIn: (s) => s.authentication.status.loggedIn,
                storeCompanyName: (s) => s.branding.companyName,
                companyLogo: (s) => s.branding.companyLogo,
                isSettingsLoaded: (s) => s.settings.isLoaded,
            }),

            showContent() {
                // If the user is logged in show the content, unless they are looking at the login page
                // If the user is not logged in show the content only of the login page. An unlogged in user viewing anything other than the login route is redirected elsewhere
                return (
                    (!!this.user && this.$route.path !== "/login") | (!this.user && this.$route.path === "/login") &&
                    this.isSettingsLoaded
                );
            },
            storeLogo() {
                if (this.companyLogo == null) {
                    return DefaultLogo;
                } else {
                    return this.companyLogo;
                }
            },
            hasMessage() {
                if (this.alert.message) {
                    return true;
                }
                return false;
            },
            getType() {
                switch (this.alert.type) {
                    case "alert-success":
                        return "success";
                    case "alert-info":
                        return "info";
                    case "alert-warning":
                        return "warning";
                    case "alert-danger":
                        return "error";
                    default:
                        return "error";
                }
            },
            forceNewPassword() {
                var user = this.user;
                return user ? user.forceNewPassword : false;
            },
        },
        watch: {
            companyName() {
                if (!this.companyName) return;
                document.title = "LiveMine | " + this.companyName;
            },
            $route() {
                // clear alert on location change
                this.$store.dispatch("alert/clear");
            },
            loggedIn() {
                if (this.loggedIn == true) {
                    this.$store.dispatch("licenseValid/checkValid");
                    this.$store.dispatch("license/loadLicense");
                    this.$store.dispatch("branding/initialize");
                    this.getUser();
                }
            },
        },
    };
</script>

<style>
    #livemine .nav-drawer-logo-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 70px;
        min-height: 32px;
        min-width: 32px;
    }

    #livemine .edit-data-table tbody tr {
        cursor: pointer;
    }

    #livemine .v-data-table-header {
        white-space: nowrap;
    }

    #livemine .app-bar-logo {
        height: 32px;
        padding-right: 150px;
        transform: scale(0.99);
    }

    #livemine .branding-name {
        text-align: center;
        font-size: 0.8125rem;
        font-weight: 500;
    }

    #livemine .alert-bar {
        z-index: 202;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    img.contain {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }

    .clickable {
        cursor: pointer;
    }

    /* Vertical / Horizontal Centering helper classes */
    .v-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .v-center.right {
        justify-content: right;
    }
    .v-center.left {
        justify-content: left;
    }

    .grab {
        cursor: grab;
    }

    @media print {
        /* Hide the header and navigation and get the main display area to take up the entire page when printing */
        #livemine nav {
            display: none !important;
        }

        #livemine header {
            display: none !important;
        }

        #livemine main {
            padding: 0 !important;
        }

        .no-print {
            display: none !important;
        }

        .page-break {
            page-break-before: always;
        }

        table {
            word-break: break-all;
        }

        @page {
            size: auto;
            margin: 0;
        }

        body {
            margin: 0;
            padding: 1cm 0.5cm;
            print-color-adjust: exact;
        }

        .overflow-y-auto {
            overflow-y: auto;
        }

        .overflow-x-auto {
            overflow-x: auto;
        }
    }
</style>
