<template>
    <div class="d-flex flex-nowrap align-center justify-center">
        <v-autocomplete
            ref="autocomplete"
            v-model="valueLocal"
            :items="items"
            :loading="loading"
            :search-input.sync="search"
            :label="label"
            :rules="rules"
            :disabled="readonly"
            :item-text="itemText"
            :item-value="itemValue"
            :outlined="outlined"
            :dense="dense"
            hide-no-data
            :cache-items="cacheItems || filterValues == null || filterValues.length == 0"
            :clearable="clearable"
            :multiple="multiple"
            :hide-details="hideDetails"
            :background-color="backgroundColor"
            :return-object="returnObject"
            :auto-select-first="isAutoSelectFirst"
            @change="valueChanged($event)"
        >
            <template v-if="showChips" v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    class="ma-1"
                    small
                    label
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                >
                    {{ data.item[itemText] }}
                </v-chip>
            </template>
            <template v-slot:append-item>
                <div v-intersect.quiet="endIntersect" />
            </template>
        </v-autocomplete>
        <v-btn v-if="addForm && !readonly && !disableAdd" text small fab class="ml-2" @click="add">
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog persistent v-model="dialog" max-width="500px">
            <component :is="addForm" v-model="editedItem" @add="onAdd" @cancel="closeDialog" :filter="filter" />
        </v-dialog>
    </div>
</template>

<script>
    import axios from "axios";
    import Vue from "vue";

    export default Vue.component("autocomplete", {
        props: {
            label: String,
            url: String,
            value: null,
            rules: null,
            itemText: String,
            itemValue: String,
            addForm: null,
            filter: null,
            filterValues: null,
            outlined: Boolean,
            dense: Boolean,
            readonly: Boolean,
            hideDetails: Boolean,
            disableAdd: Boolean,
            backgroundColor: String,
            returnObject: Boolean,
            chips: Boolean,
            defaultOne: Boolean,
            clearable: {
                type: Boolean,
                default: true,
            },
            multiple: Boolean,
            cacheItems: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                lastSearch: null,
                search: null,
                loading: false,
                items: [],
                dialog: false,
                editedItem: null,
                pageNumber: 0,
                skip: 0,
                take: 50,
                controller: null,
                hasInitalized: false,
                isAutoSelectFirst: true,
                isFetching: false,
            };
        },

        methods: {
            /**
             * Reset the autocomplete to its initial state.
             */
            async reset() {
                if (typeof this.$refs.autocomplete !== "undefined" && this.$refs.autocomplete !== null) {
                    this.$refs.autocomplete.cachedItems = [];
                }
                this.search = null;
                this.lastSearch = null;
                this.items = [];
                this.pageNumber = 0;
                this.skip = 0;
                this.take = 50;
                await this.load();
            },
            add() {
                this.editedItem = null;
                this.dialog = true;
            },
            onAdd(item) {
                if (this.multiple) {
                    this.valueLocal.push(item[this.itemValue]);
                } else {
                    this.valueLocal = item[this.itemValue];
                }

                this.closeDialog();
            },
            closeDialog() {
                this.dialog = false;
            },

            async load() {
                try {
                    // Do not want to fetch items while another fetch is happening.
                    if (this.isFetching) return;

                    this.loading = true;
                    this.skip = this.pageNumber * this.take;
                    this.pageNumber += 1;
                    var params = { search: this.validSearch, skip: this.skip, take: 50 };
                    var response = null;

                    if (typeof this.filter !== "undefined" && this.filter !== null) {
                        Object.keys(this.filter).forEach((key) => (params["filter." + key] = this.filter[key]));
                    }

                    response = await this.getItems(this.url, params);

                    if (this.lastSearch !== this.validSearch) {
                        return;
                    }

                    this.items = [
                        ...this.items,
                        ...response.data.results.filter(
                            (x) => !this.items.some((e) => x[this.itemValue] == e[this.itemValue])
                        ),
                    ];

                    // New list of items may not have the currently selected values, if so need to remove them.
                    // Edge case: If a person populates v-model via mounted,
                    // this can get called before that value comes through and then causes a race condition which can end up wiping the v-model back to null.
                    // Wait for hasInitalized to be true.
                    if (this.hasInitalized && this.valueLocal != null && this.items != null && this.items.length > 0) {
                        const value = this.getItemValue(this.valueLocal);
                        if (this.multiple) {
                            let newValue = this.items.filter((x) => value.includes(x[this.itemValue]));
                            this.valueLocal = this.returnObject ? newValue : newValue.map((x) => x[this.itemValue]);
                        } else {
                            let filtered = this.items.filter((x) => x[this.itemValue] == value);
                            if (filtered.length == 0) {
                                this.valueLocal = null;
                            }
                        }
                    }

                    //filter out an array of ids                             only if they have declared value key
                    if (this.filterValues && this.filterValues.length > 0 && this.itemValue) {
                        let filtered = this.items.filter((x) => !this.filterValues.includes(x[this.itemValue]));
                        this.items = filtered;
                    }

                    if (this.defaultOne && this.items.length == 1) {
                        const valueToSelect = this.returnObject ? this.items[0] : this.items[0][this.itemValue];
                        if (this.multiple) {
                            this.valueLocal = [valueToSelect];
                        } else {
                            this.valueLocal = valueToSelect;
                        }
                    }
                } catch {
                    // fail silently
                } finally {
                    this.loading = false;
                }
            },

            //https://stackoverflow.com/a/67813494
            async endIntersect(entries, observer, isIntersecting) {
                if (!isIntersecting) {
                    return;
                }

                if (this.lastSearch === this.validSearch) {
                    // When search string is present, turn off auto-select-first to ensure it does not scroll to top
                    this.isAutoSelectFirst = false;
                }

                await this.load();
                //Turn auto-select-first back on
                if (!this.isAutoSelectFirst) {
                    await this.$nextTick();
                    this.isAutoSelectFirst = true;
                }
            },

            async getItems(url, params = null) {
                this.isFetching = true;
                const response = await axios.get(url, { params });
                this.isFetching = false;
                return response;
            },

            /**
             * Returns a list of values or a single value mapped by this.itemValue
             */
            getItemValue(value) {
                if (!this.returnObject) {
                    return value;
                }

                if (this.multiple) {
                    return value.map((x) => x[this.itemValue]);
                }

                return value[this.itemValue];
            },

            valueChanged(event) {
                this.$emit("change", event);
            },

            remove(item) {
                let index = -1;
                if (this.returnObject) {
                    index = this.valueLocal.indexOf(item);
                } else {
                    index = this.valueLocal.indexOf(item[this.itemValue]);
                }
                if (index >= 0) this.valueLocal.splice(index, 1);
            },
        },

        computed: {
            valueLocal: {
                get() {
                    return this.value;
                },
                set(value) {
                    if (typeof value === "undefined") {
                        value = null;
                    }
                    this.$emit("input", value);
                },
            },

            showChips: function () {
                return this.chips && this.multiple;
            },

            validSearch() {
                if (this.multiple) {
                    return this.search;
                }

                const item = this.items.find((x) => x[this.itemText] == this.search);
                if (item !== undefined && item[this.itemText] == this.search) {
                    return null;
                }

                return this.search;
            },
        },

        watch: {
            validSearch: {
                immediate: false,
                async handler(value) {
                    if (this.readonly) {
                        return;
                    }

                    if (this.lastSearch != value) {
                        this.pageNumber = 0;
                    }

                    this.lastSearch = value;

                    // When search is empty, either the v-model is empty or the v-model is the search term.
                    // Reset() is called when the v-model is empty, and more items will be loaded when the user
                    // edits if v-model is the search term, so don't need to call load().
                    if (!value) {
                        return;
                    }

                    await this.load();
                    // Ensure auto-select-first remains on after search changes
                    // This is set here to ensure it overrides the endIntersect isAutoSelectFirst toggle
                    this.isAutoSelectFirst = true;
                },
            },

            value: {
                immediate: true,
                async handler(value, oldValue) {
                    if (typeof value === "undefined" || value === null) {
                        await this.reset();
                        return;
                    }

                    // This watch function should only fire when the value is different,
                    // though sometimes the objects are "different" yet the properties are the exact same.
                    // This only handles when both objects have properties in the exact same order.
                    if (JSON.stringify(oldValue) === JSON.stringify(value)) {
                        return;
                    }

                    value = this.getItemValue(value);

                    // If the selected value (or values) are not available in the list, then load them.
                    let ids = [];
                    if (this.multiple) {
                        ids = value;
                    } else {
                        ids = [value];
                    }

                    var itemIds = this.items.map((i) => i[this.itemValue]);
                    if (!ids.some((id) => !itemIds.includes(id))) {
                        // There are no ids missing, so we can return.
                        if (this.multiple) {
                            await this.load();
                        }
                        return;
                    }

                    // Need to load into items the current values, the normal load() function will only grab 50 at a time.
                    var url = `${this.url}?state=All&take=${ids.length}`;
                    if (this.multiple) {
                        // This is the fancy way of getting a list of Guids on the server.
                        for (var id of ids) {
                            url += `&ids=${id}`;
                        }
                    } else {
                        url += `&filter.${this.itemValue}=${value}`;
                    }

                    try {
                        const { data } = await this.getItems(url);
                        this.items = data.results;
                    } catch {
                        // fail silently
                    } finally {
                        this.hasInitalized = true;
                    }
                },
            },

            filter: {
                deep: true,
                async handler(newValue, oldValue) {
                    // This watch function should only fire when the value is different,
                    // though sometimes the objects are "different" yet the properties are the exact same.
                    // This only handles when both objects have properties in the exact same order.
                    if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
                        return;
                    }
                    if (newValue == null || oldValue == null) {
                        return;
                    }
                    await this.reset();
                },
            },
        },
    });
</script>
