import Vue from "vue";
import axios from "axios";

export default Vue.mixin({
    methods: {
        async filterIfPlodtrackEnabled(filter) {
            const { data } = await axios.get("/api/Plodtrack/IsPlodtrackEnabled");
            let isPlodtrackEnabled = data ?? false;
            if (!isPlodtrackEnabled) {
                return { ...filter, externalId: null, plodtrackEnabled: false };
            }
            return { ...filter, plodtrackEnabled: true };
        },
    },
});
