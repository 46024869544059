<template>
    <v-btn
        :height="buttonHeight"
        :class="currentClass"
        :href="getLink"
        target="_blank"
        color="primary"
        style="text-transform: none"
        outlined
    >
        <v-icon left>mdi-help-circle-outline</v-icon>
        Get Help
    </v-btn>
</template>

<script>
    import Vue from "vue";

    export default Vue.component("help-link", {
        props: ["link", "buttonClass", "buttonHeight"],

        computed: {
            currentClass() {
                if (this.buttonClass != null) {
                    return this.buttonClass;
                }
                return "mr-n1";
            },

            getLink() {
                let linkKey = this.link || this.$route?.meta?.helpLink;
                return this.$store.getters.getHelpLink(linkKey);
            },
        },
    });
</script>
