<template>
    <div class="node-tree">
        <div class="c-tree-icon-spacer" v-if="!canExpandInternal(item)"></div>
        <v-icon v-if="canExpandInternal(item) && !expanded" @click="expand">mdi-menu-right</v-icon>
        <v-icon v-if="expanded" @click="collapse">mdi-menu-down</v-icon>

        <slot name="label" :item="item" :parent="parent"></slot>

        <div v-if="expanded" class="c-tree-children">
            <template v-if="item.children && item.children.length">
                <node
                    v-for="(child, index) in item.children"
                    :item="child"
                    :parent="item"
                    :key="index"
                    @expand="onExpand"
                    :canExpand="canExpand"
                >
                    <template v-slot:label="{ item, parent }">
                        <slot name="label" :item="item" :parent="parent"></slot>
                    </template>
                    <template v-slot:append="{ item }">
                        <slot name="append" :item="item"></slot>
                    </template>
                </node>
            </template>

            <div class="c-tree-icon-spacer"></div>
            <slot name="append" :item="item" :parent="parent"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "node",
        props: ["item", "parent", "canExpand"],
        data() {
            return { expanded: false };
        },
        methods: {
            expand() {
                this.expanded = true;
                this.$emit("expand", this.item);
            },
            collapse() {
                this.expanded = false;
                this.$emit("collapse", this.item);
            },
            onExpand(item) {
                this.$emit("expand", item);
            },
            canExpandInternal() {
                if (typeof this.canExpand === "undefined" || this.canExpand === null) {
                    return typeof this.children === "undefined" || this.children === null || this.children.length > 0;
                } else {
                    return this.canExpand(this.item);
                }
            },
        },
    };
</script>

<style>
    .c-tree-children {
        padding-left: 25px;
    }
</style>
