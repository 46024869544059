<template>
    <v-tooltip bottom v-model="showTooltip" open-delay="500">
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                icon
                :color="value ? 'primary' : 'secondary'"
                :disabled="disabled"
                @click="toggleEdit(!value)"
            >
                <v-icon>{{ value ? "mdi-lock-open" : "mdi-lock" }}</v-icon>
            </v-btn>
        </template>
        <span>
            {{ tooltipMessage }}
        </span>
    </v-tooltip>
</template>

<script>
    import Vue from "vue";
    export default Vue.component("lock-button", {
        props: ["tooltipMessage", "value", "disabled"],
        data() {
            return {
                showTooltip: true,
            };
        },
        methods: {
            toggleEdit(onoff) {
                this.$emit("input", onoff);
            },
        },
        mounted() {
            setTimeout(() => (this.showTooltip = false), 2000);
        },
    });
</script>

<style></style>
