<template>
    <div class="d-flex flex-nowrap align-center justify-center">
        <date-field
            v-bind="$attrs"
            v-model="dateFieldValue"
            :label="label + ' (Date)'"
            @input="onInput"
            :required="required"
            :rules="rules"
        />
        <v-text-field v-model="time" :label="label + ' (Time)'" @input="onInput" type="time" />
        <v-text-field
            v-if="!hideOffset"
            v-model="offset"
            :label="'UTC Offset'"
            @blur="onInput"
            type="text"
            :required="required"
            style="width: 0px"
            :rules="offsetRules"
        />
        <v-btn text small fab class="ml-2" @click="clear"><v-icon>mdi-close</v-icon></v-btn>
    </div>
</template>

<script>
    import Vue from "vue";
    import { DateTime } from "luxon";
    import { now, toIsoOffset, tryParseOffset, parseIso, localize, parseFromFormat } from "../services/dateUtility";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";

    function parseIsoOrNull(value) {
        let dateTime = parseIso(value);

        if (dateTime === null || !dateTime.isValid) {
            return null;
        }

        return dateTime;
    }

    // Consider the following represents a full date with timezone:
    // 2019-09-28T18:00:00+08:00

    export default Vue.component("date-time-field", {
        inheritAttrs: false,
        props: {
            label: String,
            value: String,
            required: Boolean,
            rules: Array,
            hideOffset: Boolean,
        },

        data() {
            return {
                date: null,
                time: null,
                offset: null,
                valid: true,
                offsetRules: [
                    (v) => {
                        return !this.required || tryParseOffset(v).isValid ? true : "Invalid offset";
                    },
                ],
            };
        },
        computed: {
            dateFieldValue: {
                get() {
                    if (isNullOrWhiteSpace(this.date)) {
                        return null;
                    }
                    let dateTime = parseFromFormat(this.date, "yyyy-MM-dd");
                    return localize(dateTime).toISO();
                },
                set(value) {
                    // v-date-picker accepts inputs as yyyy-MM-dd:
                    let dateTime = parseIsoOrNull(value);
                    this.date = dateTime?.toFormat("yyyy-MM-dd");
                },
            },
        },
        methods: {
            fillDateTime(value, includeTime = true) {
                var dateTime = DateTime.fromISO(value, { setZone: true });
                this.date = dateTime.toISODate();
                if (includeTime) this.time = dateTime.toFormat("HH:mm");
                this.offset = toIsoOffset(dateTime.offset);
            },
            onInput() {
                var hasDate = typeof this.date !== "undefined" && this.date !== null && this.date !== "";
                var hasTime = typeof this.time !== "undefined" && this.time !== null && this.time !== "";
                var parseOffsetResult = tryParseOffset(this.offset);

                if (hasDate && parseOffsetResult.isValid) {
                    var offset = toIsoOffset(parseOffsetResult.offset);
                    var time = hasTime ? this.time : "00:00";
                    var output = this.date + "T" + time + ":00" + offset;
                    this.$emit("input", output);
                    this.valid = true;
                } else {
                    this.valid = !this.required;
                }
            },
            clear() {
                this.$emit("input", null);
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(value) {
                    var offset = toIsoOffset(now().offset);
                    if (typeof value === "undefined" || value === null) {
                        this.date = null;
                        this.time = null;
                        this.offset = offset;
                    } else {
                        this.fillDateTime(value);
                    }
                    if (typeof this.offset === "undefined" || this.offset === null) {
                        this.offset = offset;
                    }
                },
            },
        },
    });
</script>
