import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default {
    //eslint-disable-next-line
    install(Vue) {
        // use new Vue instance as an event bus
        const livemineHub = new Vue();
        // every component will use this.$livemineHub to access the event bus
        Vue.prototype.$livemineHub = livemineHub;

        const connection = new HubConnectionBuilder()
            .withUrl(`/livemine-hub`)
            .configureLogging(LogLevel.Information)
            .build();

        // set up event listeners

        // Forward server side SignalR events through $livemineHub, where components will listen to them
        connection.on("RefreshClaimsAsync", () => {
            livemineHub.$emit("refresh-claims");
        });

        connection.on("BroadcastMessageAsync", (message) => {
            livemineHub.$emit("broadcast-message", { message });
        });

        connection.on("UserAccountDeactivatedAsync", (userId) => {
            livemineHub.$emit("user-account-deactivated", { userId });
        });

        let startedPromise = null;
        function start() {
            startedPromise = connection.start().catch(() => {
                return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 15000));
            });
            return startedPromise;
        }
        connection.onclose(() => start());

        // start the connection to Hub
        start();
    },
};
