<template>
    <v-list dense class="menu-items">
        <v-card tile height="64" flat>
            <v-card-text class="pa-3">
                <v-text-field
                    clearable
                    v-model="search"
                    dense
                    hide-details
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                ></v-text-field>
            </v-card-text>
        </v-card>
        <v-list :expand="expand">
            <template v-for="item in items">
                <v-list-group v-if="item.children" :key="item.text" :value="expand">
                    <template v-slot:activator>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </template>

                    <v-list-item v-for="(child, i) in item.children" class="blue-grey lighten-5" :key="i">
                        <!--Grand child element-->
                        <v-list-group sub-group no-action v-if="child.children" class="ml-n7" :value="expand">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <router-link
                                v-for="(grandChild, j) in child.children"
                                :key="j"
                                :to="route(grandChild.route, grandChild.filter)"
                                class="mt-n7"
                            >
                                <v-list-item class="blue-grey lighten-5" link>
                                    <v-list-item-action v-if="grandChild.icon">
                                        <v-icon :style="grandChild.iconStyle">{{ grandChild.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ grandChild.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </router-link>
                        </v-list-group>
                        <!-- End grand child element-->

                        <router-link :to="route(child.route, child.filter)" v-else class="ml-n5">
                            <v-list-item>
                                <v-list-item-action v-if="child.icon">
                                    <v-icon :style="child.iconStyle">{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link>
                    </v-list-item>
                </v-list-group>

                <router-link v-else :key="`rl-${item.text}`" :to="route(item.route, item.item, item.operationId)">
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon :style="item.iconStyle">{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </template>
        </v-list>
    </v-list>
</template>

<script>
    import axios from "axios";
    import userInterfaces from "../enums/userInterfaceEnum";
    import securityService from "../services/securityService";
    import { mapMutations, mapGetters, mapState } from "vuex";

    export default {
        data() {
            return {
                filter: {
                    operationId: null,
                },

                timeUsage: false,
                equipmentReview: false,
                shiftAllocation: false,
                productionDrillVisible: false,
                diamondDrillVisible: false,
                faceSampling: false,
                weightDistanceEnabled: false,

                modules: [],
                search: null,
                menuItems: [
                    { icon: "mdi-home", text: "Home", route: "/" },
                    {
                        icon: "mdi-clock-outline",
                        text: "Shift Review",
                        route: "/shift-review",
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-clock-outline",
                        text: "Equipment Review",
                        route: "/equipment-review",
                        visible: () => this.isLicenseValid(this) && this.equipmentReview,
                    },
                    {
                        icon: "mdi-clipboard-account",
                        text: "Shift Planning",
                        route: "/shift-planning",
                        visible: () => this.isLicenseValid(this) && this.shiftAllocation,
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Reports",
                        children: [
                            {
                                icon: "mdi-chart-histogram",
                                text: "Daily Shift Reports",
                                route: "/daily-shift-reports",
                                claim: "DailyShiftReports",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-tablet-cellphone",
                                text: "Device Status Report",
                                route: "/device-status-report",
                                claim: "DeviceStatusReport",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-chart-areaspline",
                                text: "Time Usage Report",
                                route: "/time-usage-report",
                                claim: "TimeUsageReport",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-chart-areaspline",
                                text: "Maintenance Report",
                                route: "/maintenance-report",
                                claim: "MaintenanceReport",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-chart-box-outline",
                                text: "Meters Report",
                                route: "/meters-report",
                                claim: "MetersReport",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-crosshairs",
                                text: "Production Drill Report",
                                route: "/production-drill-report",
                                claim: "ProductionDrillReport",
                                requiresWrite: false,
                                visible: () => this.productionDrillVisible,
                            },
                            {
                                icon: "mdi-crosshairs",
                                text: "Diamond Drill Report",
                                route: "/diamond-drill-report",
                                claim: "DiamondDrills",
                                requiresWrite: false,
                                visible: () => this.diamondDrillVisible,
                            },
                            {
                                icon: "mdi-debug-step-into",
                                text: "Diamond Drill Bit Report",
                                route: "/diamond-drill-bit-report",
                                claim: "DiamondDrills",
                                requiresWrite: false,
                                visible: () => this.diamondDrillVisible,
                            },
                            {
                                icon: "mdi-timeline-clock-outline",
                                text: "Operator Time Usage Report",
                                route: "/operator-time-usage-report",
                                claim: "OperatorTimeUsageReport",
                                requiresWrite: false,
                                visible: () => this.timeUsage,
                            },
                            {
                                icon: "mdi-timeline-clock-outline",
                                text: "Location Time Usage Report",
                                route: "/location-time-usage-report",
                                claim: "LocationTimeUsageReport",
                                requiresWrite: false,
                                visible: () => this.timeUsage,
                            },
                            {
                                icon: "mdi-timeline-clock-outline",
                                text: "Equipment Time Usage Report",
                                route: "/equipment-time-usage-report",
                                claim: "EquipmentTimeUsageReport",
                                requiresWrite: false,
                                visible: () => this.timeUsage,
                            },
                            {
                                icon: "mdi-graph-outline",
                                text: "TKM Report",
                                route: "/tkm-report",
                                claim: "LocationDistances",
                                requiresWrite: false,
                                visible: () => this.weightDistanceEnabled,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "User",
                        children: [
                            {
                                icon: "mdi-chevron-up",
                                text: "Operators - Tablets",
                                children: [
                                    {
                                        icon: "mdi-account-box",
                                        text: "Operators",
                                        route: "/operators",
                                        claim: "Operators",
                                        requiresWrite: false,
                                    },
                                    {
                                        icon: "mdi-tablet",
                                        text: "Device Approval",
                                        route: "/devices",
                                        claim: "Devices",
                                        requiresWrite: false,
                                    },
                                    //{ icon: 'mdi-tablet-cellphone', text: 'Device Types', route: '/device-types', claim: "DeviceTypes", requiresWrite: false },
                                ],
                                visible: () => this.isLicenseValid(this),
                            },
                            {
                                icon: "mdi-chevron-up",
                                text: "Administrators - Desktop",
                                children: [
                                    {
                                        icon: "mdi-account-tie",
                                        text: "Users",
                                        route: "/users",
                                        claim: "Users",
                                        requiresWrite: false,
                                    },
                                    {
                                        icon: "mdi-shield-account",
                                        text: "Roles",
                                        route: "/roles",
                                        claim: "Roles",
                                        requiresWrite: false,
                                    },
                                ],
                                visible: () => this.isLicenseValid(this),
                            },
                            {
                                icon: "mdi-chevron-up",
                                text: "Public API",
                                children: [
                                    {
                                        icon: "mdi-account-tie",
                                        text: "API Clients",
                                        route: "/api-clients",
                                        claim: "ApiClients",
                                        requiresWrite: false,
                                    },
                                ],
                                visible: () => this.isLicenseValid(this),
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Mine Management",
                        children: [
                            {
                                icon: "mdi-gesture-tap",
                                text: "Activities",
                                route: "/activities",
                                claim: "Activities",
                                requiresWrite: false,
                                filter: this.filter,
                            },
                            {
                                icon: "mdi-clock-start",
                                text: "Shifts",
                                route: "/shifts",
                                claim: "Shifts",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-image-filter-hdr",
                                text: "Products",
                                route: "/products",
                                claim: "Products",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-ruler",
                                text: "Unit of Measure",
                                route: "/units",
                                claim: "Units",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-sitemap",
                                text: "Sites",
                                route: "/sites",
                                claim: "Sites",
                                requiresWrite: false,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Time Management",
                        children: [
                            {
                                icon: "mdi-clock-start",
                                text: "Time Usage Categories",
                                route: "/time-usage-categories",
                                claim: "TimeUsageCategories",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-gesture-tap",
                                text: "Time Usage Activities",
                                route: "/time-usage-activities",
                                claim: "TimeUsageActivities",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-crosshairs-gps",
                                text: "Time Usage Mapping",
                                route: "/time-usage-category-mapping",
                                claim: "TimeUsageCategories",
                                requiresWrite: true,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Locations",
                        children: [
                            {
                                icon: "mdi-crosshairs",
                                text: "Location Types",
                                route: "/location-types",
                                claim: "LocationTypes",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-crosshairs-gps",
                                text: "Locations",
                                route: "/locations",
                                claim: "Locations",
                                requiresWrite: false,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Equipment",
                        children: [
                            {
                                icon: "mdi-alpha-m-box",
                                text: "Equipment Makes",
                                route: "/makes",
                                claim: "Makes",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-alpha-m-box-outline",
                                text: "Equipment Models",
                                route: "/models",
                                claim: "Models",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-dump-truck",
                                text: "Equipment",
                                route: "/equipments",
                                claim: "Equipments",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-flag-variant",
                                text: "Equipment Statuses",
                                route: "/equipment-statuses",
                                claim: "EquipmentStatuses",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-format-list-checks",
                                text: "Prestart Checklists",
                                route: "/checklists",
                                claim: "Checklists",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-format-list-checks",
                                text: "Prestart Checklists (tree view)",
                                route: "/checklist-tree",
                                claim: "Checklists",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-counter",
                                text: "Meters",
                                route: "/meters",
                                claim: "Meters",
                                requiresWrite: false,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Inventory",
                        children: [
                            {
                                icon: "mdi-hammer",
                                text: "Inventory Categories",
                                route: "/consumable-groups",
                                claim: "ConsumableGroups",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-bolt",
                                text: "Inventory Items",
                                route: "/consumables",
                                claim: "Consumables",
                                requiresWrite: false,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Modules",
                        children: [
                            {
                                icon: "mdi-chevron-up",
                                text: "Face Sampling",
                                children: [
                                    {
                                        icon: "mdi-gate-and",
                                        iconStyle: {
                                            transform: "rotate(-90deg)",
                                        },
                                        text: "Requests",
                                        route: "/face-sampling",
                                        claim: "FaceSampleRequests",
                                        requiresWrite: false,
                                    },
                                    {
                                        icon: "mdi-image-multiple",
                                        text: "Background Images",
                                        route: "/face-sample-background-images",
                                        claim: "FaceSampleRequests",
                                        requiresWrite: false,
                                    },
                                ],
                                visible: () => this.faceSampling,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "Shift Planning Setup",
                        children: [
                            {
                                icon: "mdi-account-group",
                                text: "Crews",
                                route: "/crews",
                                claim: "Crews",
                                requiresWrite: true,
                            },
                            {
                                icon: "mdi-clipboard",
                                text: "Shift Allocation Configuration",
                                route: "/shift-allocation-configurations",
                                claim: "ShiftAllocationConfigurations",
                                requiresWrite: true,
                            },
                            {
                                icon: "mdi-clipboard-file",
                                text: "Shift Sheet Configuration",
                                route: "/shift-sheet",
                                claim: "ShiftSheetConfiguration",
                                requiresWrite: true,
                            },
                        ],
                        visible: () => this.isLicenseValid(this) && this.shiftAllocation,
                    },
                    {
                        icon: "mdi-chevron-up",
                        text: "LiveMine Setup",
                        children: [
                            {
                                icon: "mdi-view-module",
                                text: "Modules",
                                route: "/modules",
                                claim: "Modules",
                                requiresWrite: true,
                                visible: () => this.user.isMaster,
                            },
                            {
                                icon: "mdi-pickaxe",
                                text: "Operations",
                                route: "/operations",
                                claim: "Operations",
                                requiresWrite: false,
                                visible: () => this.user.isMaster,
                            },
                            {
                                icon: "mdi-card-bulleted",
                                text: "Company Branding",
                                route: "/branding",
                            },
                            {
                                icon: "mdi-bulldozer",
                                text: "PLODtrack",
                                route: "/plodtrack",
                                claim: "PlodtrackConfiguration",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-dump-truck",
                                text: "LoadScan",
                                route: "/loadscan",
                                claim: "LoadScanConfiguration",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-scale",
                                text: "Transcale",
                                route: "/transcale",
                                claim: "TranscaleConfiguration",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-crosshairs",
                                text: "Minnovare",
                                route: "/minnovare",
                                claim: "MinnovareConfiguration",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-sync",
                                text: "LiveMine API Sync",
                                route: "/apisync",
                                claim: "ApiSyncConfiguration",
                                requiresWrite: false,
                            },
                            {
                                icon: "mdi-cogs",
                                text: "Settings",
                                route: "/settings",
                                claim: "Settings",
                                requiresWrite: true,
                                visible: () => this.user.isMaster,
                            },
                        ],
                        visible: () => this.isLicenseValid(this),
                    },
                    {
                        icon: "mdi-license",
                        text: "Licensing",
                        route: "/license",
                        claim: "Licensing",
                        requiresWrite: false,
                    },
                    {
                        icon: "mdi-routes",
                        text: "All Routes",
                        route: "/master-router",
                        claim: "MasterRouter",
                        requiresWrite: false,
                        visible: () => this.isLicenseValid(this) && this.user.isMaster,
                    },
                ],
            };
        },
        async mounted() {
            this.filter = await this.filterIfPlodtrackEnabled(this.filter);
            this.refreshWebSettings();

            this.timeUsage = (await this.getDeviceSetting("timeUsage")) ?? false;
            if (!this.timeUsage) {
                this.filter = { ...this.filter, timeUsageCategoryId: null, timeUsageCategory: null };
            }
            if (typeof this.filter === "undefined" || this.filter === null) {
                this.filter = {};
            }

            this.modules = await this.getModules();

            this.productionDrillVisible = this.hasModule(userInterfaces.ProductionDrill);
            this.diamondDrillVisible = this.hasModule(userInterfaces.DiamondDrill);

            var moduleManagement = this.menuItems.filter((m) => m.text === "Modules")[0];

            moduleManagement.children = moduleManagement.children.concat(await this.getModuleMenuItems());
        },
        methods: {
            ...mapMutations("modules", ["setModules", "setModuleName"]),
            ...mapMutations("settings", ["setPendingUpdate"]),
            hasModule(userInterface) {
                for (let module of this.modules) {
                    if (module.userInterface == userInterface) {
                        return true;
                    }
                }
                return false;
            },

            async getModules() {
                if (!this.hasClaim("Modules", false)) {
                    return [];
                }
                let params = { state: "Active" };
                let response = await axios.get("/api/Modules", { params });
                return response.data.items;
            },

            async getModuleMenuItems() {
                let modules = this.modules;

                //using vuex in case we need to use this data elsewhere in the future
                //we just want the name and operationId
                this.setModules(modules);

                //force set moduleName for first load since $route watcher doesn't pick it up
                this.updateModuleName();

                return modules.map(this.getModuleMenuItem).filter((m) => m !== null);
            },

            getModuleMenuItem(module) {
                this.filter = {
                    ...this.filter,
                    operationId: module.operationId,
                    moduleName: module.name,
                    moduleId: module.id,
                    userInterface: module.userInterface,
                };
                let common = {
                    icon: "mdi-chevron-up",
                    text: module.name,
                    visible: () => this.isLicenseValid(this),
                };

                switch (module.userInterface) {
                    case userInterfaces.DevelopmentDrill:
                    case userInterfaces.AirLeg:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/development-drill-activities",
                                    claim: "DevelopmentDrillActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-content-cut",
                                    text: "Cut Lengths",
                                    route: "/development-drill-cut-lengths",
                                    claim: "DevelopmentDrillCutLengths",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Hole Types",
                                    route: "/development-drill-types",
                                    claim: "DevelopmentDrillTypes",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-cube-outline",
                                    text: "Drill Standards",
                                    route: "/development-drill-standards",
                                    claim: "DevelopmentDrillStandards",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-clipboard-file-outline",
                                    text: "Site Instructions",
                                    route: "/instruction-plan-files",
                                    claim: "InstructionPlanFiles",
                                    requiresWrite: false,
                                    filter: { ...this.filter, showLatestPlan: true },
                                },
                            ],
                        };

                    case userInterfaces.ProductionDrill:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/production-drill-activities",
                                    claim: "ProductionDrillActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-book-open-page-variant",
                                    text: "Drill Plans",
                                    route: "/production-drill-plans",
                                    claim: "ProductionDrillPlans",
                                    requiresWrite: false,
                                    filter: { ...this.filter, comments: null },
                                },
                                {
                                    icon: "mdi-radius",
                                    text: "Hole Sizes",
                                    route: "/production-drill-hole-sizes",
                                    claim: "ProductionDrillHoleSizes",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.OpenPitDrill:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/production-drill-activities",
                                    claim: "ProductionDrillActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-book-open-page-variant",
                                    text: "Drill Plans",
                                    route: "/production-drill-plans",
                                    claim: "ProductionDrillPlans",
                                    requiresWrite: false,
                                    filter: { ...this.filter, isOpenPit: true },
                                },
                                {
                                    icon: "mdi-radius",
                                    text: "Collar Pipe Lengths",
                                    route: "/dimension-lookups",
                                    claim: "DimensionLookups",
                                    requiresWrite: false,
                                    filter: { ...this.filter, type: 2 },
                                },
                                {
                                    icon: "mdi-radius",
                                    text: "Hole Sizes",
                                    route: "/dimension-lookups",
                                    claim: "DimensionLookups",
                                    requiresWrite: false,
                                    filter: { ...this.filter, type: 0 },
                                },
                                {
                                    icon: "mdi-radius",
                                    text: "Sub Drills",
                                    route: "/dimension-lookups",
                                    claim: "DimensionLookups",
                                    requiresWrite: false,
                                    filter: { ...this.filter, type: 1 },
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.ServiceCrew:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/service-activities",
                                    claim: "ServiceActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.DiamondDrill:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/diamond-drill-activities",
                                    claim: "DiamondDrillActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-vector-line",
                                    text: "Rod Lengths",
                                    route: "/diamond-drill-rod-lengths",
                                    claim: "DiamondDrillRodLengths",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-debug-step-into",
                                    text: "Drill Bits",
                                    route: "/diamond-drill-bits",
                                    claim: "DiamondDrillBits",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-crosshairs",
                                    text: "Holes",
                                    route: "/diamond-drill-holes",
                                    claim: "DiamondDrillHoles",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-circle-double",
                                    text: "Hole Sizes",
                                    route: "/diamond-drill-hole-sizes",
                                    claim: "DiamondDrillHoleSizes",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-crosshairs-off",
                                    text: "Location Type Filters",
                                    route: "/location-type-filters",
                                    claim: "ModuleLocationTypes",
                                    requiresWrite: false,
                                    filter: {
                                        ...this.filter,
                                        isLoadingLocation: null,
                                        isMaterialSource: null,
                                        isToLocation: null,
                                    },
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.Charge:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/charge-up-activities",
                                    claim: "ChargeUpActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                                {
                                    icon: "mdi-cube-outline",
                                    text: "ChargeUp Standards",
                                    route: "/charge-up-standards",
                                    claim: "ChargeUpStandards",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                            ],
                        };

                    case userInterfaces.Shotcrete:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/shotcrete-activities",
                                    claim: "ShotcreteActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bucket",
                                    text: "Shotcrete Products",
                                    route: "/shotcrete-products",
                                    claim: "ShotcreteProducts",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-ruler",
                                    text: "Shotcrete Thickness",
                                    route: "/shotcrete-thicknesses",
                                    claim: "ShotcreteThicknesses",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-cube-outline",
                                    text: "Shotcrete Standards",
                                    route: "/shotcrete-standards",
                                    claim: "ShotcreteStandards",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.CableBolt:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/cable-bolt-activities",
                                    claim: "CableBoltActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-vector-circle",
                                    text: "Hole Lengths",
                                    route: "/cable-bolt-hole-lengths",
                                    claim: "CableBoltHoleLengths",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-radius",
                                    text: "Hole Sizes",
                                    route: "/cable-bolt-hole-sizes",
                                    claim: "CableBoltHoleSizes",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.AgiTruck:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/activities",
                                    claim: "Activities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bucket",
                                    text: "Agi Truck Products",
                                    route: "/shotcrete-products",
                                    claim: "ShotcreteProducts",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.RaiseBore:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/raise-bore-activities",
                                    claim: "RaiseBoreActivities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-vector-line",
                                    text: "Rod Lengths",
                                    route: "/raise-bore-rod-lengths",
                                    claim: "RaiseBoreRodLengths",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-debug-step-into",
                                    text: "Drill Bits",
                                    route: "/raise-bore-bits",
                                    claim: "RaiseBoreBits",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-crosshairs",
                                    text: "Holes",
                                    route: "/raise-bore-holes",
                                    claim: "RaiseBoreHoles",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-circle-double",
                                    text: "Hole Sizes",
                                    route: "/raise-bore-hole-sizes",
                                    claim: "RaiseBoreHoleSizes",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    case userInterfaces.Haul:
                    case userInterfaces.HaulOpenPit:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/activities",
                                    claim: "Activities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-crosshairs-off",
                                    text: "Location Type Filters",
                                    route: "/location-type-filters",
                                    claim: "ModuleLocationTypes",
                                    requiresWrite: false,
                                    filter: { ...this.filter, isEnabled: null },
                                },
                                {
                                    icon: "mdi-table",
                                    text: "Location Distances",
                                    route: "/location-distances",
                                    claim: "LocationDistances",
                                    requiresWrite: true,
                                    filter: null,
                                    visible: () => this.weightDistanceEnabled,
                                },
                                {
                                    icon: "mdi-graph-outline",
                                    text: "Location Distances (graph)",
                                    route: "/location-network",
                                    claim: "LocationDistances",
                                    requiresWrite: true,
                                    filter: null,
                                    visible: () => this.weightDistanceEnabled,
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };
                    case userInterfaces.Load:
                        return {
                            ...common,
                            children: [
                                {
                                    icon: "mdi-gesture-tap",
                                    text: "Activities",
                                    route: "/activities",
                                    claim: "Activities",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-account-box",
                                    text: "Operators",
                                    route: "/operator-modules",
                                    claim: "OperatorModules",
                                    requiresWrite: true,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-crosshairs-off",
                                    text: "Location Type Filters",
                                    route: "/location-type-filters",
                                    claim: "ModuleLocationTypes",
                                    requiresWrite: false,
                                    filter: { ...this.filter, isEnabled: null },
                                },
                                {
                                    icon: "mdi-clipboard-file-outline",
                                    text: "Remote Loading Plans",
                                    route: "/instruction-plan-files",
                                    claim: "InstructionPlanFiles",
                                    requiresWrite: false,
                                    filter: { ...this.filter, showLatestPlan: true },
                                },
                                {
                                    icon: "mdi-format-list-bulleted-type",
                                    text: "Inventory Categories",
                                    route: "/consumable-group-operations",
                                    claim: "ConsumableGroupOperations",
                                    requiresWrite: false,
                                    filter: this.filter,
                                },
                                {
                                    icon: "mdi-bolt",
                                    text: "Inventory Items",
                                    route: "/consumables",
                                    claim: "Consumables",
                                    requiresWrite: false,
                                    filter: { ...this.filter, holeLength: null },
                                },
                            ],
                        };

                    default:
                        return null;
                }
            },

            filterOutInvisibleMenuItems(menuItems) {
                return menuItems
                    .filter(
                        (mi) =>
                            (mi.visible === undefined || mi.visible()) && // Filter out items marked as invisible
                            (mi.claim === undefined || this.hasClaim(mi.claim, mi.requiresWrite))
                    ) // Filter out items that require a claim we don't have
                    .map((mi) => {
                        // If we have children iterate over them and filter out the invisible ones
                        if (mi.children === undefined) {
                            return mi;
                        } else {
                            let clonedObj = Object.assign({}, mi);
                            clonedObj.children = this.filterOutInvisibleMenuItems(clonedObj.children);
                            return clonedObj;
                        }
                    })
                    .filter((mi) => {
                        // Menu items with an empty list of children are in that state because they the user doesn't any of the necessary claims. We can safely remove the entire menu
                        return mi.children === undefined || mi.children.length > 0;
                    });
            },

            isLicenseValid(t) {
                return t.$store.state.licenseValid.status.licenseState;
            },

            hasClaim(name, requiresReadWrite) {
                return securityService.hasClaim(name, requiresReadWrite);
            },

            route(path, filter) {
                if (!path) {
                    return;
                }
                var options = { path };
                if (filter) {
                    options.query = { filter };
                }

                return options;
            },
            updateModuleName() {
                if (this.$route.query.filter && this.$route.query.filter.operationId) {
                    //get the module and store the name to vuex
                    var m = this.modules.find((x) => x.value == this.$route.query.filter.operationId);

                    if (m) {
                        this.setModuleName(m.text);
                    }
                }
            },
            /**
             * Get associated web settings that are used to determine if a menu item should be visible
             * @returns {any}
             */
            async refreshWebSettings() {
                this.equipmentReview = (await this.getWebSetting("enableEquipmentTimelineReview")) ?? false;

                this.faceSampling = (await this.getDeviceSetting("faceSampling")) ?? false;

                this.shiftAllocation = (await this.getWebSetting("enableShiftAllocation")) ?? false;

                this.weightDistanceEnabled = this.settings.enableWeightDistance;

                //Set pending update back to false incase the setting is changed again
                this.setPendingUpdate(false);
            },
        },
        watch: {
            $route: {
                immediate: true,
                deep: true,
                handler() {
                    this.updateModuleName();
                },
            },
            //See settingsModule.js store file
            async pendingUpdate() {
                //If the pending update is true, then we need to refresh the web settings
                if (this.pendingUpdate) {
                    await this.refreshWebSettings();
                }
            },
        },

        computed: {
            ...mapGetters("settings", ["pendingUpdate", "settings"]),
            ...mapState({
                user: (s) => s.authentication.user,
            }),
            items() {
                var filterOutInvisibleMenuItems = this.filterOutInvisibleMenuItems(this.menuItems);

                if (this.search == null) {
                    return filterOutInvisibleMenuItems;
                }

                var searchedItems = filterOutInvisibleMenuItems.filter((item) => {
                    if (item.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                        return true;
                    }
                    if (item.children == null) return false;
                    item.children = item.children.filter((child) => {
                        if (child.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                            return true;
                        }
                        if (child.children == null) return false;
                        child.children = child.children.filter((grandChild) => {
                            return grandChild.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                        });
                        return child.children.length > 0;
                    });
                    return item.children.length > 0;
                });
                return searchedItems;
            },
            expand() {
                return this.search?.length > 0;
            },
        },
    };
</script>

<style type="text/css">
    #livemine .v-list-item__action:first-child {
        margin-right: 16px;
    }

    #livemine .menu-items a {
        text-decoration: none;
        color: inherit;
    }
</style>
