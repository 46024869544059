import securityService from "../services/securityService";
import router from "../router";

export const authentication = {
    namespaced: true,
    state: {
        status: {},
        user: null,
    },
    actions: {
        async initialize({ commit }) {
            try {
                var user = await securityService.getLoggedInUser();
                commit("loginSuccess", user);
            } catch {
                /* let the interceptor handle any redirection to the login page. */
            }
        },
        login({ dispatch, commit }, { username, password }) {
            commit("loginRequest", { username });

            securityService
                .login(username, password)
                .then((response) => {
                    commit("loginSuccess", response.data);
                    router.push("/");
                })
                .catch((error) => {
                    commit("loginFailure", error);
                    dispatch("alert/error", error, { root: true });
                });
        },
        loginSuccess({ commit }, user) {
            commit("loginSuccess", user);
        },
        logout({ commit }) {
            commit("logout");
            securityService.logout();
            if (router.currentRoute.path !== "/login") {
                router.push("/login");
            }
        },
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        },
    },
};
