import Vue from "vue";

export default Vue.mixin({
    methods: {
        compareString(a, b) {
            if (typeof a !== "string") {
                return -1;
            } else if (typeof b !== "string") {
                return 1;
            } else {
                return a.localeCompare(b);
            }
        },
    },
});
