import Vue from "vue";
import axios from "axios";

export default Vue.mixin({
    methods: {
        async getWebSetting(name) {
            var settingResponse = await axios.get("/api/Settings/GetWeb");
            var settings = settingResponse.data;
            for (var setting in settings) {
                if (name == setting) {
                    return settings[setting];
                }
            }
            return null;
        },

        async getDeviceSetting(name) {
            var settingResponse = await axios.get("/api/Settings/");
            var settings = settingResponse.data;
            for (var setting in settings) {
                if (name == setting) {
                    return settings[setting];
                }
            }
            return null;
        },
    },
});
