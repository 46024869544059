import { getLocale, getTimeZone } from "@/localization/localizationProvider";
import { DateTime } from "luxon";

export const types = ["time", "timeonly"];

export function format(value) {
    if (value == null) {
        return "";
    }

    // In the absense of an offset, the time will be interpreted as UTC before converting.
    let dateTime = DateTime.fromISO(value, { setZone: true, zone: "utc" }) // setZone will override zone here.
        .setLocale(getLocale())
        .setZone(getTimeZone());

    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
}
