<template>
    <v-dialog persistent v-model="dialog" max-width="500px">
        <v-form @submit="ok" @submit.prevent>
            <v-card>
                <v-card-title>
                    <span class="headline">
                        <slot name="title" />
                    </span>
                </v-card-title>
                <v-card-text>
                    <slot />
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn type="submit" color="primary" text>Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import Vue from "vue";

    export default Vue.component("message-dialog", {
        data() {
            return {
                dialog: true,
            };
        },
        methods: {
            ok() {
                this.$emit("input");
            },
        },
    });
</script>
