import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import AgiTruck from "../icons/AgiTruck.vue";
import AirLeg from "../icons/AirLeg.vue";
import Cablebolter from "../icons/Cablebolter.vue";
import ChargeUp from "../icons/ChargeUp.vue";
import DiamondDrilling from "../icons/DiamondDrilling.vue";
import Excavator from "../icons/Excavator.vue";
import ExplorationDrilling from "../icons/ExplorationDrilling.vue";
import Jumbo from "../icons/Jumbo.vue";
import Loader from "../icons/Loader.vue";
import Longhole from "../icons/Longhole.vue";
import Nipper from "../icons/Nipper.vue";
import OpenPitLoad from "../icons/OpenPitLoad.vue";
import Raisebore from "../icons/Raisebore.vue";
import ServiceCrew from "../icons/ServiceCrew.vue";
import Shotcrete from "../icons/Shotcrete.vue";
import Truck from "../icons/Truck.vue";
import FormatInkHighlighter from "../icons/FormatInkHighlighter.vue";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
        values: {
            "format-ink-highlighter": { component: FormatInkHighlighter },
            "agi-truck": { component: AgiTruck },
            "air-leg": { component: AirLeg },
            cablebolter: { component: Cablebolter },
            "charge-up": { component: ChargeUp },
            "diamond-drilling": { component: DiamondDrilling },
            excavator: { component: Excavator },
            "exploration-drilling": { component: ExplorationDrilling },
            "development-drill": { component: Jumbo },
            loader: { component: Loader },
            "production-drill": { component: Longhole },
            nipper: { component: Nipper },
            "open-pit-load": { component: OpenPitLoad },
            raisebore: { component: Raisebore },
            "service-crew": { component: ServiceCrew },
            shotcrete: { component: Shotcrete },
            haul: { component: Truck },
        },
    },
    theme: {
        themes: {
            light: {
                primary: "#f7901e",
                secondary: "#003A49",
                tertiary: "#f7901e",
                quaternary: "#f7901e",
                quinary: "#003A49",
                accent: "#f7901e",
                error: "#f7581e",
            },
        },
    },
});
