<template>
    <v-dialog persistent v-model="showDialog" width="90%">
        <div style="position: relative">
            <v-btn class="close-button" dark icon @click="showDialog = false">
                <v-icon class="clickable" dark>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-carousel v-if="Array.isArray(value)" height="90vh">
            <v-carousel-item v-for="(file, i) in value" :key="i">
                <v-sheet tile height="100%" class="d-flex align-center justify-center">
                    <file-image v-if="file.hasOwnProperty('id')" :value="file.id" />
                    <file-image v-else :value="file" />
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
        <v-sheet v-else tile height="90vh" class="d-flex align-center justify-center">
            <file-image v-if="value.hasOwnProperty('id')" :value="value.id" />
            <file-image v-else :value="value" />
        </v-sheet>
    </v-dialog>
</template>

<script>
    import fileImage from "./FileImage.vue";
    import Vue from "vue";

    export default Vue.component("files-viewer", {
        props: ["value"],
        components: {
            fileImage,
        },
        data() {
            return {
                showDialog: true,
            };
        },
        watch: {
            showDialog(value) {
                if (value === false) {
                    this.$emit("close");
                }
            },
        },
    });
</script>

<style scoped>
    .close-button {
        position: absolute;
        display: inline-block;
        right: 16px;
        top: 16px;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.3);
    }
</style>
