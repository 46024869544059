// Provides a map between types and dependencies (js files). Each dependency must contain an array called "types".
// Typically used to resolve a specific service for a given data type.
export class TypeDependencyProvider {
    #dependenciesByType;

    constructor(context, useDefault = true) {
        const dependenciesByType = {};

        context.keys().forEach((filename) => {
            const file = context(filename);

            const dependency = useDefault ? file.default : file;

            if (!dependency.types) {
                throw `Unable to find the 'types' property in ${filename}.`;
            }

            dependency.types.forEach((type) => (dependenciesByType[type.toLowerCase()] = dependency));
        });

        this.#dependenciesByType = dependenciesByType;
    }

    getDependency(type) {
        return this.#dependenciesByType[type.toLowerCase()] ?? this.#dependenciesByType["default"];
    }
}
