<template>
    <div>
        <v-card class="my-1">
            <vue-signature-pad
                width="100%"
                height="200px"
                ref="signaturePad"
                :options="{
                    onBegin: () => {
                        $refs.signaturePad.resizeCanvas();
                    },
                    onEnd,
                }"
            />
        </v-card>
        <div class="d-flex">
            <v-messages
                class="flex-grow-1"
                :role="hasMessages ? 'alert' : null"
                :value="messagesToDisplay"
                :color="hasHint ? '' : validationState"
            />
            <v-btn @click="clear">Clear</v-btn>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VueSignaturePad from "vue-signature-pad";
    import VInput from "vuetify/lib/components/VInput/VInput.js";

    Vue.use(VueSignaturePad);

    export default Vue.component("signature", {
        extends: VInput,

        data() {
            return {};
        },

        methods: {
            signaturePad() {
                return this.$refs.signaturePad;
            },
            clear() {
                this.signaturePad().clearSignature();
                this.$emit("input", null);
            },
            onEnd() {
                var signature = this.signaturePad().saveSignature("image/svg+xml");
                var value = signature.isEmpty ? null : signature.data;
                this.$emit("input", value);
            },
            onBegin() {
                this.signaturePad().resizeCanvas();
            },
        },

        // TODO two way binding
        //watch: {
        //    value: {
        //        immediate: true,
        //        deep: true,
        //        handler(value) {
        //            if (value) {
        //                this.signature = value;
        //            }
        //        },
        //    },
        //},
    });
</script>
