import { getLocale, getTimeZone } from "@/localization/localizationProvider";
import { formatOffset, offsetMatchesCurrentTimeZone } from "@/services/dateUtility";
import { DateTime } from "luxon";

export const types = ["date", "dateonly"];

export function format(value) {
    if (value == null) {
        return "";
    }

    // In the absense of an offset, the date will be interpreted as the system timezone before converting.
    let dateTime = DateTime.fromISO(value, { setZone: true, zone: getTimeZone() }).setLocale(getLocale());
    const format = DateTime.DATE_SHORT;
    const result = dateTime.toLocaleString(format);

    // Only format the offset if it's different from the user's current time zone.
    if (offsetMatchesCurrentTimeZone(dateTime)) {
        return result;
    }

    return `${result}\xa0${formatOffset(dateTime.offset)}`;
}
