import Vue from "vue";
import axios from "axios";

export default Vue.mixin({
    methods: {
        async filterIfMinnovareEnabled(filter) {
            const { data } = await axios.get("/api/Minnovare/IsMinnovareEnabled");
            let minnovareEnabled = data ?? false;
            return { ...filter, minnovareEnabled: minnovareEnabled };
        },
    },
});
