import successEnum from "../../enums/successEnum";

class ProgressItem {
    constructor(message, type) {
        this.message = message;
        this.type = type;
    }

    class() {
        return this.type == successEnum.Success ? "success--text" : "error--text";
    }
}

export const apiSync = {
    namespaced: true,
    state: {
        status: [],
        syncComplete: false,
        syncFailed: false,
    },
    actions: {
        reset({ commit }) {
            commit("reset");
        },
        appendSuccessMessage({ commit }, message) {
            commit("appendMessage", new ProgressItem(message, successEnum.Success));
        },
        appendErrorMessage({ commit }, message) {
            commit("appendMessage", new ProgressItem(message, successEnum.Error));
        },
        syncComplete({ commit }) {
            commit("syncComplete");
        },
        syncFailed({ commit }) {
            commit("syncFailed");
        },
    },
    mutations: {
        reset(state) {
            state.status = [];
            state.syncComplete = false;
            state.syncFailed = false;
        },
        appendMessage(state, message) {
            state.status.push(message);
        },
        syncComplete(state) {
            state.syncComplete = true;
        },
        syncFailed(state) {
            state.syncComplete = true;
            state.syncFailed = true;
        },
    },
};
