<template>
    <!-- $attr: Pass attributes from wrapper to vuetify component -->
    <!-- $listeners: Pass events from wrapper to vuetify component -->
    <v-file-input v-bind="$attrs" v-on="$listeners" v-model="files" :rules="combinedRules" :multiple="multiple">
        <!-- #region Pass Slots https://stackoverflow.com/a/66062466 -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
        <!-- #endregion -->
    </v-file-input>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        inheritAttrs: false, // Forcing the attributes to be passed via v-bind=$attrs instead of inheriting them
        props: {
            value: {
                type: [File, Array],
                default: () => null,
            },
            multiple: {
                type: Boolean,
                default: () => false,
            },
            /* Specify the maximum file size in MB (override the web setting configuration) */
            maxSize: {
                type: Number,
                default: () => null,
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            requiredMessage: {
                type: String,
                default: () => "File is required",
            },
            rules: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                files: this.value,
                defaultRules: [
                    (v) => {
                        if (this.required && (!v || v.length == 0)) return this.requiredMessage;
                        return true;
                    },
                    (v) =>
                        v && (Array.isArray(v) ? v : [v]).some((file) => this.toMb(file.size) > this.size)
                            ? this.sizeErrorMessage
                            : true,
                ],
            };
        },
        methods: {
            toMb(bytes) {
                //bytes / 1024 / 1024
                return (bytes / 1024 ** 2).toFixed(2);
            },
        },
        computed: {
            //Retrieve the max size from the store (webSettings)
            ...mapState("settings", ["maxFileSizeMB"]),
            size() {
                return this.maxSize ?? this.maxFileSizeMB;
            },
            sizeErrorMessage() {
                return `File size must be less than ${this.size}MB`;
            },
            combinedRules() {
                if (this.rules && this.rules.length > 0) {
                    return [...this.defaultRules, ...this.rules];
                }
                return this.defaultRules;
            },
        },
        watch: {
            //Update the value when the files change
            files: {
                immediate: true,
                deep: true,
                handler() {
                    this.$emit("input", this.files);
                },
            },
        },
    };
</script>
