import axios from "axios";
import store from "../store/store";

export default function () {
    axios.interceptors.response.use(
        (response) => response,

        function (error) {
            if (error.response && error.response.status === 401) {
                store.dispatch("authentication/logout");
            }
            throw error;
        }
    );
}
