<template>
    <v-container fluid id="shift-review">
        <v-card>
            <v-card-title>
                <h2>Set Password</h2>
            </v-card-title>
            <v-spacer />
            <v-card-text>
                <div v-if="forceNewPassword" class="d-flex">
                    <p class="headline">You need to set a new password to continue using LiveMine.</p>
                </div>
                <!--<v-spacer v-if="forceNewPassword" />-->
                <div class="d-flex">
                    <v-btn color="primary" dark @click="SetPassword">Set Password</v-btn>
                </div>

                <v-dialog persistent v-model="dialog" max-width="500px">
                    <v-form v-if="!error">
                        <v-card>
                            <v-card-title>
                                <span class="headline">Change Password</span>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field v-model="oldPassword" label="Old Password" :type="'password'" required />
                                <v-text-field
                                    v-model="newPassword"
                                    label="New Password"
                                    :type="'password'"
                                    :rules="[passwordConfirmationRule]"
                                    required
                                />
                                <v-text-field
                                    v-model="confirmNewPassword"
                                    label="Confirm New Password"
                                    :type="'password'"
                                    :rules="[passwordConfirmationRule]"
                                    required
                                />
                            </v-card-text>
                            <v-card-actions>
                                <div class="flex-grow-1"></div>
                                <v-btn color="quaternary" text @click="close">Cancel</v-btn>
                                <v-btn color="quaternary" text @click="save">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                    <v-form v-if="error">
                        <v-card>
                            <v-card-text>{{ this.errorMessage }}</v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn @click="closeError">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import axios from "axios";
    export default {
        components: {},
        data() {
            return {
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
                dialog: false,
                error: false,
                errorMessage: null,
            };
        },

        computed: {
            passwordConfirmationRule() {
                return this.newPassword === this.confirmNewPassword || "Password must match";
            },
            forceNewPassword() {
                var user = this.$store.state.authentication.user;
                return user.forceNewPassword;
            },
        },

        methods: {
            SetPassword() {
                this.dialog = true;
            },
            close() {
                this.dialog = false;
            },
            closeError() {
                this.error = false;
                this.errorMessage = null;
            },
            async save() {
                let response = null;
                try {
                    var body = { OldPassword: this.oldPassword, NewPassword: this.newPassword };
                    response = await axios.post("api/Security/ChangePassword", body);
                } catch (error) {
                    this.error = true;
                    if (error.response.status === 400) {
                        this.errorMessage = error.response.data;
                    } else {
                        this.errorMessage = "An internal server error has occurred";
                    }
                    return;
                }
                const { dispatch } = this.$store;
                dispatch("authentication/loginSuccess", response.data);

                this.$router.push("/");
                //this.dialog = false;
            },
        },
    };
</script>
