<template>
    <v-row class=".flex-nowrap">
        <v-col cols="auto">
            <v-row class="mb-5" justify="center" no-gutters>
                <v-btn-toggle v-model="toggleControl" mandatory>
                    <v-btn value="undo" text icon :disabled="disabled" @click="undo()"><v-icon>mdi-undo</v-icon></v-btn>
                    <v-btn value="redo" text icon :disabled="disabled" @click="redo()"><v-icon>mdi-redo</v-icon></v-btn>
                    <v-btn value="delete" text icon :disabled="disabled" @click="clear()">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        value="deleteForever"
                        v-if="updatedImage != null"
                        text
                        icon
                        :disabled="disabled"
                        @click="clearSavedImage()"
                    >
                        <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                    <v-btn value="draw" text icon :disabled="disabled" @click="draw()">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn value="text" text icon :disabled="disabled" @click="text()">
                        <v-icon>mdi-format-text</v-icon>
                    </v-btn>
                    <v-btn value="circle" text icon :disabled="disabled" @click="circle()">
                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                    </v-btn>
                    <v-btn value="rectangle" text icon :disabled="disabled" @click="rectangle()">
                        <v-icon>mdi-crop-square</v-icon>
                    </v-btn>
                    <v-btn value="arrow" text icon :disabled="disabled" @click="arrow()">
                        <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-btn value="select" text icon :disabled="disabled" @click="select()">
                        <v-icon>mdi-arrow-all</v-icon>
                    </v-btn>
                    <!-- #region Image Background Uploader -->
                    <v-btn value="backgroundUpload" text icon :disabled="disabled" @click="$refs.inputUpload.click()">
                        <v-icon>mdi-file-upload</v-icon>
                    </v-btn>
                    <input
                        v-show="false"
                        ref="inputUpload"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        multiple
                        @change="handleFiles"
                    />
                    <!-- #endregion -->
                    <v-btn value="download" v-if="!hideSaveButton" text icon :disabled="disabled" @click="saveImage()">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            <v-row no-gutters justify="center">
                <Editor
                    :canvasWidth="canvasWidth"
                    :canvasHeight="canvasHeight"
                    ref="editor"
                    editorId="1"
                    style="outline: 2px solid black"
                />
            </v-row>
        </v-col>
        <v-col cols="auto" align-self="center" style="margin-top: 68px" :class="{ disabled: disabled }">
            <div
                class="color-option"
                :class="{ selected: color == '#e40000' }"
                style="background-color: #e40000"
                @click="!disabled && setColor('#e40000')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#fdd835' }"
                style="background-color: #fdd835"
                @click="!disabled && setColor('#fdd835')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#a834eb' }"
                style="background-color: #a834eb"
                @click="!disabled && setColor('#a834eb')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#65c31a' }"
                style="background-color: #65c31a"
                @click="!disabled && setColor('#65c31a')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#34b7eb' }"
                style="background-color: #34b7eb"
                @click="!disabled && setColor('#34b7eb')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#eb34df' }"
                style="background-color: #eb34df"
                @click="!disabled && setColor('#eb34df')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#1a10ad' }"
                style="background-color: #1a10ad"
                @click="!disabled && setColor('#1a10ad')"
            />
            <div
                class="color-option"
                :class="{ selected: color == '#000' }"
                style="background-color: #000"
                @click="!disabled && setColor('#000')"
            />
        </v-col>
    </v-row>
</template>

<script>
    import Vue from "vue";
    import Editor from "vue-image-markup";

    export default {
        name: "draw",

        props: {
            backgroundImage: String,
            updatedImage: String,
            disabled: {
                type: Boolean,
                default: false,
            },
            hideSaveButton: {
                type: Boolean,
                default: false,
            },
            canvasWidth: {
                type: Number,
                default: 500,
            },
            canvasHeight: {
                type: Number,
                default: 500,
            },
        },

        components: {
            Editor,
        },

        data() {
            return {
                color: "#000",
                toggleControl: "select",
                lastFunction: this.select,
                imageUrl: this.backgroundImage,
            };
        },

        watch: {
            updatedImage: {
                immediate: true,
                async handler() {
                    if (this.updatedImage != null) {
                        this.imageUrl = this.updatedImage;
                    }
                    await Vue.nextTick();
                    this.$refs.editor.setBackgroundImage(this.imageUrl);
                },
            },

            disabled: {
                async handler() {
                    this.toggleControl = "select";
                    this.select();
                },
            },
        },

        methods: {
            getStrokeColor() {
                return { stroke: this.color };
            },

            getFillColor() {
                return { fill: this.color };
            },

            setColor(newColor) {
                this.color = newColor;
                this.lastFunction();
            },

            async undo() {
                this.$refs.editor.undo();

                await Vue.nextTick();
                this.toggleControl = "draw";
                this.draw();
            },

            async redo() {
                this.$refs.editor.redo();

                await Vue.nextTick();
                this.toggleControl = "draw";
                this.draw();
            },

            async clear() {
                if (
                    confirm(
                        "This will restore the previously saved state of the image, are you sure you want to do this?"
                    )
                ) {
                    this.$refs.editor.clear();
                    this.$refs.editor.setBackgroundImage(this.imageUrl);
                }
                await Vue.nextTick();
                this.toggleControl = "draw";
                this.draw();
            },

            async clearSavedImage() {
                if (confirm("This will reset the image so you can start again, are you sure you want to do this?")) {
                    this.$refs.editor.clear();

                    this.imageUrl = this.backgroundImage;
                    this.$refs.editor.setBackgroundImage(this.imageUrl);

                    this.$emit("clear");
                }
                await Vue.nextTick();
                this.toggleControl = "draw";
                this.draw();
            },

            draw() {
                this.$refs.editor.set("freeDrawing", this.getStrokeColor());
                this.lastFunction = this.draw;
            },

            text() {
                this.$refs.editor.set("text", this.getFillColor());
                this.lastFunction = this.text;
            },

            circle() {
                this.$refs.editor.set("circle", this.getStrokeColor());
                this.lastFunction = this.circle;
            },

            rectangle() {
                this.$refs.editor.set("rect", this.getStrokeColor());
                this.lastFunction = this.rectangle;
            },

            arrow() {
                this.$refs.editor.set("arrow", this.getStrokeColor());
                this.lastFunction = this.arrow;
            },

            select() {
                this.$refs.editor.set("selectMode");
                this.lastFunction = () => {
                    return;
                };
            },

            async handleFiles(e) {
                this.$refs.editor.uploadImage(e);

                await Vue.nextTick();
                this.toggleControl = "draw";
                this.draw();
            },

            async saveImage() {
                const image = this.$refs.editor.saveImage();

                // Switch back to drawing mode when save button is hit.
                if (!this.hideSaveButton) {
                    this.$emit("save-image", image);

                    await Vue.nextTick();
                    this.toggleControl = "draw";
                    this.draw();
                }

                return image;
            },

            async updateBackgroundImage(base64Image) {
                await this.$refs.editor.setBackgroundImage(base64Image);
            },
        },
    };
</script>

<style scoped>
    .color-option {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .selected {
        outline: black 2px solid;
        outline-offset: 3px;
    }

    .disabled {
        opacity: 0.2;
    }
</style>
