import { getLocale } from "@/localization/localizationProvider";
import { formatOffset, offsetMatchesCurrentTimeZone } from "@/services/dateUtility";
import { DateTime } from "luxon";

export const types = ["datetimeoffset"];

export function format(value) {
    if (value == null) {
        return "";
    }

    let dateTime = DateTime.fromISO(value, { setZone: true }).setLocale(getLocale());
    const noTime = dateTime.hour === 0 && dateTime.minute === 0 && dateTime.second === 0 && dateTime.millisecond === 0;
    const format = noTime ? DateTime.DATE_SHORT : DateTime.DATETIME_SHORT;
    let result = dateTime.toLocaleString(format);

    // Only format the offset if it's different from the user's current time zone.
    if (offsetMatchesCurrentTimeZone(dateTime)) {
        return result;
    }

    return `${result}\xa0${formatOffset(dateTime.offset)}`;
}
