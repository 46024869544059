import { updateField } from "vuex-map-fields";
export default {
    updateField,
    setDraggingActivity(state, draggingActivity) {
        state.draggingActivity = draggingActivity;
    },
    setSlots(state, slots) {
        state.slots = slots;
    },
    showForm(state, show) {
        //Get the current user interface
        let selectedUserInterface = state.modules[state.selectedModule].userInterface;

        state.schema[selectedUserInterface].dialog = show;
    },
};
