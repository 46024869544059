<template>
    <div class="tree">
        <node
            v-for="(item, key) in items"
            :item="item"
            :parent="null"
            :key="key"
            @expand="onExpand"
            :canExpand="canExpand"
        >
            <template v-slot:label="{ item, parent }">
                <slot name="label" :item="item" :parent="parent"></slot>
            </template>

            <template v-slot:append="{ item }">
                <slot name="append" :item="item"></slot>
            </template>
        </node>
        <div class="c-tree-icon-spacer"></div>
        <slot name="append" :item="null"></slot>
    </div>
</template>

<script>
    import node from "./TreeNode";

    export default {
        name: "tree",
        props: ["items", "canExpand"],
        components: {
            node,
        },
        methods: {
            onExpand(item) {
                this.$emit("expand", item);
            },
        },
    };
</script>

<style>
    .c-tree-icon-spacer {
        display: inline-block;
        width: 24px;
    }
</style>
